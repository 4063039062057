import React from 'react';
import { MapItemStyled } from './ItemBox.style';
import PropTypes from 'prop-types';

const MapItemBox = ({
  item: { title, children }
}) => (
  <MapItemStyled className="tree-map__item-container">
    <div className="tree-map__item-arrow">
      <div className="dot" />
      <div className="bottom dot" />
    </div>
    <div className="tree-map__item-box">
      <div className="tree-map__box-title">{title}</div>
      {children.map((line) => (
        <div key={line} className="tree-map__box-line">
          {line}
        </div>
      ))}
    </div>
  </MapItemStyled>
);

MapItemBox.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.string)
  })
};

export default MapItemBox;
