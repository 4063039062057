import styled, { keyframes } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div<{ size?: number }>`
  z-index: 1000;
  animation: ${rotate360} 0.8s linear infinite;
  transform: translateZ(0);

  border-style: solid;
  border-width: 3px;
  border-color: #ffffff
    ${({ theme }) =>
    `${theme.colors.main} ${theme.colors.main} ${theme.colors.main}`};

  background: transparent;
  width: ${(props) => props.size || 70}px;
  height: ${(props) => props.size || 70}px;
  border-radius: 50%;
`;

export default Spinner;
