import styled from 'styled-components';
import { injectColor, injectSize } from '../../theme';
import { device } from '../../responsive';

export const HeaderStyled = styled.div`
  display: flex;
  background-color: white;
  border-bottom: 1px solid ${injectColor('separator')};
  height: ${injectSize('upper_header')}px;
  a {
    cursor: pointer;
    width: ${injectSize('upper_header_width')}px;
    min-width: ${injectSize('upper_header_width')}px;
    border-right: 1px solid ${injectColor('separator')};
    display: flex;
    align-items: center;
    justify-content: center;

    .arrow-left {
      position: relative;
      height: 41px;
      width: 41px;

      &:hover {
        path:first-child {
          fill: ${injectColor('main')};
        }

        background-color: ${injectColor('bgLightDarker')};
      }
    }
  }
  h1 {
    font-family: Roboto, Sans-Serif, serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: ${injectColor('textDarker')};
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 24px;
    text-align: left;

    @media ${device.tablet} {
      font-size: 20px;
    }

    @media ${device.laptop} {
      margin-left: 40px;
      font-size: 30px;
      font-weight: 500;
    }
  }

  .open-in-new {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 16px 20px;
    color: ${injectColor('main')};
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;

    &:hover {
      transition: background-color 0.2s ease-in-out;
      background-color: rgba(136, 188, 212, 0.15);
      cursor: pointer;
    }
  }
`;

export const AddonsContainer = styled.div`
  margin: 0 24px 0 24px;
  flex: 1;
  display: flex;
  justify-content: flex-end;

  @media ${device.laptop} {
    margin-right: 40px;
  }
`;
