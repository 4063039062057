import styled from 'styled-components';

import { injectColor } from 'theme';

const PageStyled = styled.div`
  position: relative;
  display: flex;
  background-color: ${injectColor('bgLightDarkest')};
  flex-direction: column;
  align-content: flex-start;

  width: calc(
    100vw - ${(props) => (props.hideSidenav ? 0 : props.theme.sizes.sidenav)}px
  );
  padding: 82px 0 64px 0;
  h2 {
    position: absolute;
    top: 80px;
    color: ${injectColor('mapSeparator')};
    font-size: 32px;
  }
  .home-scroll .simplebar-content {
    height: 100%;
  }

  .page-header {
    z-index: 1000;
    position: absolute;
    left: 0;
    top: 0;
    width: calc(
      100vw -
        ${(props) => (props.hideSidenav ? 0 : props.theme.sizes.sidenav)}px
    );
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05), 0 3px 5px 0 rgba(0, 0, 0, 0.1);

    &__addons {
      margin-top: 23px;
    }
  }

  > .spinner {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 40%;
  }
`;

export { PageStyled };
