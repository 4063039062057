/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import qs from 'query-string';
import { useCMSState } from 'c2-cms-plugin';
import { Telemetry } from 'c2-common-ui';

import { ReactComponent as Plus } from '../../../assets/plus.svg';
import Badge from '../../../components/Badge';
import Ellipsis from '../../../components/Ellipsis';
import New from '../../../components/New';
import { sortOrder } from '../../../utils';
import { ARTICLES_LIST, BUTTON, CLICK, CLICK_ARTICLE, CMS } from '../../../variables/telemetryVariables';

import {
  MoreItems,
  RootCategoryContainer,
  RootCategoryHeader,
  RootCategoryStyled,
  RootCategoryWrapper
} from './RootCategory.style';

const RootCategory = ({ className, root }) => {
  // Component States
  const [shorten, setShorten] = React.useState(true);

  const { notification } = useCMSState();
  const { notificationsCategories, notificationsArticles } = notification;

  const rootContents = React.useMemo(
    () =>
      root.sub_categories
        .sort(sortOrder)
        .concat(
          root.articles
            .map((i) => ({ ...i, article: true }))
            .sort(sortOrder)
        ),
    [root]
  );

  const currentContent = React.useMemo(
    () => rootContents.slice(0, shorten ? 3 : rootContents.length),
    [rootContents, shorten]
  );

  const moreItems = React.useMemo(
    () => rootContents.length - currentContent.length,
    [rootContents.length, currentContent.length]
  );

  const hiddenContentHasNew = React.useMemo(() => {
    return (
      shorten &&
      rootContents.find(
        (i) =>
          !currentContent.find((item) => item.id === i.id) &&
          (i.article
            ? notificationsArticles.indexOf(i.id) >= 0
            : notificationsCategories.indexOf(i.id) >= 0)
      )
    );
  }, [
    rootContents,
    shorten,
    currentContent,
    notificationsArticles,
    notificationsCategories
  ]);

  const renderItem = React.useCallback(
    (item, index) => {
      const getSearch = () => {
        const values = qs.parse(window.location.search);

        values.root = String(root.id);
        return qs.stringify(values);
      };

      const handleClick = () => {
        const titleUnderscored = item.slug.replaceAll('-', '_');

        Telemetry.addUIEventMetric({
          action: CLICK,
          app: CMS,
          component: BUTTON,
          event: `${CLICK_ARTICLE}_${titleUnderscored}`,
          feature: ARTICLES_LIST
        });
      };

      return (
        <Link
          key={`${item.slug}-${index}`}
          to={{
            pathname: `/${root.slug}/${item.slug}`,
            search: getSearch()
          }}
          onClick={handleClick}
        >
          {item.title}
          {item.article ? <New article={item.id} /> : <New category={item.id} />}
        </Link>
      );
    }, [root]);

  return (
    <RootCategoryWrapper
      className={className}
      data-test-id="home-category_wrapper"
    >
      <RootCategoryStyled
        data-test-id="home-category_container"
        order={root.order}
        open={!shorten}
        onMouseEnter={() => setShorten(false)}
        onMouseLeave={() => setShorten(true)}
      >
        <RootCategoryHeader>
          <Ellipsis
            text={root.title}
            width={250}
            data-test-id="home-category_title"
          />
        </RootCategoryHeader>
        <RootCategoryContainer>
          <SimpleBar style={{ maxHeight: 220 }}>
            <ul data-test-id="home-category_list">
              {currentContent.map(renderItem)}
            </ul>
          </SimpleBar>

          {shorten && moreItems > 0 && (
            <MoreItems data-test-id="home-category_more-items">
              <Plus /> {moreItems} more
              {hiddenContentHasNew && <Badge color="primary">NEW</Badge>}
            </MoreItems>
          )}
        </RootCategoryContainer>
      </RootCategoryStyled>
    </RootCategoryWrapper>
  );
};

RootCategory.propTypes = {
  className: PropTypes.string,
  root: PropTypes.object
};

export default RootCategory;
