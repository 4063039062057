import React from 'react';

const CurrentNdaText = () => (
  <React.Fragment>
    <b>REMINDER</b>
    <br />
    <br />
    <i>Your organization has already agreed to our confidentiality terms, but as a reminder:</i>
    <ul>
      <li>Please keep the information you receive from CyberCube confidential and secure</li>
      <li>Only share with authorized third parties in the manner allowed</li>
      <li>The information is provided on an &quot;as is&quot; basis without any representation or warranty</li>
    </ul>
    <br />
    <p>
        You are being given access to CyberCube’s proprietary and confidential information (&quot;CyberCube’s Information&quot;) as part of your use of CyberCube’s products.
        We remind you to use CyberCube’s Information only in respect of your access to and use of CyberCube’s products.
        It is important that you keep CyberCube’s Information confidential and secure.
        Please do not disclose, share, circulate or otherwise make available CyberCube’s Information to third parties without first speaking with your CyberCube account manager.
        Further, please note that CyberCube’s Information is provided to you strictly and solely for your internal business purposes only and does not constitute actuarial, tax, legal or other professional advice.
        CyberCube’s Information is also provided on an &quot;as is&quot; basis without any representation or warranty whatsoever.
        No liability is accepted by CyberCube and its affiliates for any loss or damage suffered as a result of reliance on any statement or opinion, or for any error or omission, or deficiency contained in CyberCube’s Information.
        You shall be solely responsible and liable for all decisions you make pertaining to the use of the information contained in this document.
        Except as expressly granted, nothing herein shall be construed as conferring on you by implication or otherwise any licence or right to use CyberCube’s intellectual property.
    </p>
    <br />
    <p>All CyberCube’s rights are reserved. © 2021 CyberCube Analytics Inc.</p>
  </React.Fragment>
);

export default CurrentNdaText;