import React, { useEffect, useRef, useState, useMemo } from 'react';
import { EllipsisText, EllipsisTextHidden } from './Ellipsis.style';
import PropTypes from 'prop-types';

const Ellipsis = ({
  text,
  width,
  ...props
}) => {
  const ref = useRef(null);
  const [textWidth, setTextWidth] = useState(0);

  useEffect(() => {
    const newWidth = ref?.current?.clientWidth;

    if (newWidth && textWidth !== newWidth) {
      setTextWidth(newWidth);
    }
  }, [ref, setTextWidth, textWidth]);

  const tooltip = useMemo(() => (textWidth > width ? text : undefined), [
    text,
    textWidth,
    width
  ]);

  return (
    <EllipsisText
      className="ellipsis-container"
      style={{ width }}
      title={tooltip}
      {...props}
    >
      {text}
      <EllipsisTextHidden ref={ref}>{text}</EllipsisTextHidden>
      {props.children}
    </EllipsisText>
  );
};

Ellipsis.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired
};

export default Ellipsis;
