import React from 'react';
import { Input, useSearch, SearchList } from 'c2-cms-plugin';
// import BrowserInteractionTime from 'browser-interaction-time';
// import { Telemetry } from 'c2-common-ui';

import Analytics from '../../analytics';
import { getQueryStringValue, setQueryStringValue } from '../../hooks/useQueryState';

import Page from '../Page';

const SearchPage = () => {
  // Component States
  const [lastValue, setLastValue] = React.useState('');

  const {
    groups,
    items,
    loading,
    onChange,
    paging,
    setSize,
    size,
    total,
    value
  } = useSearch({
    debounce: 500,
    limit: 15,
    queryDefault: (getQueryStringValue('query')) || ''
  });

  // const metricsObj = {
  //   timeOpened: 0,
  //   lastSessionTime: 0
  // };

  const handleClear = React.useCallback(() => {
    onChange({ target: { value: '' } });
  }, [onChange]);

  const handleKeydown = React.useCallback(
    async (e) => {
      if (e.key === 'Enter') {
        setQueryStringValue('query', e.target.value);

        await setSize(1);
        Analytics.trackSearch(e.target.value);
      }
    }, [setSize]);

  // const sendMetricsToTimestream = (msg) => (time) => {
  //   if (metricsObj.timeOpened) {
  //     Telemetry.addCmsTimeEventMetric({
  //       event: 'track_search_results',
  //       page: 'search',
  //       timeOpened: metricsObj.timeOpened,
  //       timeSpent: Math.round(browserInteractionTime.getTimeInMilliseconds())
  //     });
  //   }
  // };

  // const browserInteractionTime = new BrowserInteractionTime({
  //   timeIntervalEllapsedCallbacks: [{
  //     timeInMilliseconds: 1000,
  //     callback: sendMetricsToTimestream('Interval reached. Sending metrics...'),
  //     multiplier: x => x * 1.5
  //   }],
  //   browserTabInactiveCallbacks: [sendMetricsToTimestream('Tab inactive. Sending metrics...')],
  //   idleTimeoutMs: 5000
  // });

  React.useEffect(() => {
    setLastValue('');

    // browserInteractionTime.startTimer();
    // metricsObj.timeOpened = Date.now();

    return () => {
      setQueryStringValue('query', null);

      // sendMetricsToTimestream('Refreshed/Left page. Sending metrics...');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!loading && paging.rowCount === 0 && lastValue !== value) {
      setLastValue(value);
    } else {
      setLastValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, paging.rowCount]);

  return (
    <Page
      addons={
        <Input
          addon="search"
          autoFocus
          bordered
          loading={loading}
          onChange={onChange}
          onClear={handleClear}
          onKeyDown={handleKeydown}
          type="text"
          value={value}
        />
      }
      backType="back"
      className="search-page"
      id="search"
      loading={loading}
      title="Search results"
    >
      <SearchList
        groups={groups}
        items={items}
        loading={loading}
        paging={paging}
        scrollableTarget="scrollable-search-content"
        setSize={setSize}
        size={size}
        style={{ height: '100vh' }}
        total={total}
        type="full"
      />
    </Page>
  );
};

export default SearchPage;
