export const
  CMS = 'CMS',

  // features
  SIDE_NAV = 'sidenav',
  ARTICLE = 'article',
  SEARCH = 'search',
  ARTICLES_LIST = 'articles_list',

  // components
  BUTTON = 'button',
  INPUT = 'search',
  PAGE = 'page',
  SIDE_NAW= 'SIDE_NAW',
  DROPDOWN = 'DROPDOWN',
  SIDENAV = 'SIDENAV',

  // actions
  CLICK = 'click',
  OPEN = 'open',

  // events
  CLICK_ARTICLE = 'click_article',
  OPEN_ARTICLE = 'open_article',
  SEARCH_KEYWORD = 'search_keyword',
  USER_LOGGED_OUT = 'user_logged_out',
  VISIT_ADMIN_PAGE = 'visit_admin_page',
  VISIT_HOME = 'visit_home'
;
