import { SessionStorage } from 'c2-common-ui';

import { apiPrefixEnv, urlAppsEnv, urlLoginEnv } from '../constants/environmentVariables';

export interface AppConfigObj {
  apiPrefix: string;
  urlApps: string;
  urlLogin: string;
  telemetryApiKey: string;
  telemetryUrl: string;
  telemetryDbName: string;
  SentryApiDsn: string;
  SentryTracesSampleRate: number;
  environment: string;
}

export const getEmailFromSession = () => SessionStorage.getItem('email');

export const getIdFromSession = () => SessionStorage.getItem('username');

export const getSessionIdToken = () => {
  return SessionStorage.getItem('id_token');
};

export const setEnvToSession = (newEnv: string) => SessionStorage.setItem('env', newEnv);

export const setInitialAppConfig = (appConfigObj: AppConfigObj) => {
  SessionStorage.setItem('appConfig.apiPrefix', apiPrefixEnv || appConfigObj.apiPrefix);
  SessionStorage.setItem('appConfig.urlApps', urlAppsEnv || appConfigObj.urlApps);
  SessionStorage.setItem('appConfig.urlLogin', urlLoginEnv || appConfigObj.urlLogin);
  SessionStorage.setItem('appConfig.redirect_uri', encodeURIComponent(window.location.href));
};

export const setSessionDevMode = () => {
  localStorage.setItem('devModeCyberCube', 'true');
};

export const shouldLogin = () => {
  return !getSessionIdToken();
};