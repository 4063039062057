import React from 'react';

import { MapStyled } from './TreeMap.style.js';
import MapItemBox from './ItemBox';
import PropTypes from 'prop-types';

const TreeMap = ({
  className,
  data = [],
  groupLabels = [],
  ...props
}) => (
  <MapStyled className={className} {...props}>
    <div>
      {data.map((item, index) => (
        <div key={`tree-map-root-${index}`} className="tree-map-root">
          <div className="root-title">{item.title}</div>
          {item.children.map((itemBox, i) =>
            itemBox.title ? (
              <MapItemBox
                key={`tree-map-item-box-${index}-${i}`}
                item={itemBox}
              />
            ) : (
              <div key={`tree-map-item-${index}-${i}`} className="category">
                {itemBox.label !== undefined &&
                  itemBox.label >= 0 &&
                  groupLabels[itemBox.label]}
              </div>
            )
          )}
        </div>
      ))}
    </div>
  </MapStyled>
);

TreeMap.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.string),
      label: PropTypes.number
    }))
  }),
  groupLabels: PropTypes.arrayOf(PropTypes.string)
};

export default TreeMap;
