import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { ReactComponent as CyberCubeLogo } from '../../../assets/logo.svg';
import Search from '../../Search';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const HTTP404PageStyled = styled.div`

  overflow: auto;
  background-color: #f2f2f2;
  font-size: 16px;
  width: 100vw;
  min-height: 80%;

  .error-info-page-wrapper {
    position: relative;
    top: 25%;
    height: 50%;
  }

  * {
    margin: auto;
  }

  div[role='combobox'] {
    position:relative;
    width: 286px;
  }

  .error-404-wrapper, div[data-test-id="page-header_search-autosuggest"], .go-home-link {
    margin-top:18px;
  }

  div[data-test-id="page-header_search-autosuggest"] {
    margin-top: 9px;
  }

  .error-404-wrapper {
    width: 47%;
    color: #525252;
  }

  .error-404-wrapper > * {
    width: 100%;
    text-align: center;
  }

  a.bottom-link {
  }


  .error-404 {
    font-family: Helvetica;
    font-size: 72px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .error-404-description {
    margin-top: 18px;
    font-family: Helvetica;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--greyish-brown);
  }


  button.go-home-link {
    display: block;
    width: 207px;
    height: 24px;
    font-family: Helvetica;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #19a1df;
  }

  .go-home-link:hover {
    cursor: pointer
  }

  div[role='combobox'] > * {
    width: 286px;
    min-width: 286px;
  }

  .react-autosuggest__suggestions-container {
    position:absolute;
    width: 286px;
  }

  div.bottom-page-padding {
    background-color: #f2f2f2;
    height: 6%;
    width: 100%;
  }

  div[role='listbox'] {
      overflow: hidden;
  }

  @media (max-width: 500px) {
    .cybercube-logo-container {
      margin-left:auto;
      margin-right:auto;
    }
  }

  @media (max-width: 375px) {
    width: 75vw;

    .error-404 {
      font-size: 67px;
    }

    div[role='combobox'] {
        width: 90%;
        min-width: 100px;
    }

    div[role='combobox'] > * {
        width: 220px;
        min-width: 90px;
    }
  }
`;

const HTTP404Page = ( { buttonText, onClick, ...props } ) => {

  const Global404PageStyle = createGlobalStyle`
    div[data-test-id="home-container"] {
      padding: 0px;
    }

    .cybercube-logo-container {
      position: relative;
      width: 95px;
      margin-left:auto;
      margin-right:auto;
      margin-top: 5%;
    }
  `;

  return (
    <>
      <Global404PageStyle />
      <HTTP404PageStyled >
        <div className="error-info-page-wrapper">
          <div className="error-404-wrapper">
            <p className="error-404">404</p>
            <p className="error-404-description">
              {props.children}
            </p>
          </div>
          <Search height={200}/>
          <button className="go-home-link" onClick={onClick} data-test-id="app-error-page_btn">
            {buttonText}
          </button>
          <div className="cybercube-logo-container">
            <CyberCubeLogo />
          </div>
        </div>
      </HTTP404PageStyled>
    </>
  );
};

HTTP404Page.propTypes = {
  buttonText: PropTypes.string.isRequired,
  children: PropTypes.node,
  onClick: PropTypes.func
};

export default HTTP404Page;