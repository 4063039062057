/* eslint-disable @typescript-eslint/camelcase */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './polyfills/string.repeat';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Application } from 'c2-common-ui';
import * as Sentry from '@sentry/react';

import * as serviceWorker from './serviceWorker';
import App from './App';
import history from './historyInstance';

import { startApp } from 'index.helpers';
import { getEmailFromSession, getIdFromSession, shouldLogin } from 'utils/sessionUtils';

import './headings.scss';
import './index.css';
import './jquery.mock';

Application.Bootstrap.initSessionHandler();

setTimeout(async () => {
  await startApp();

  if (shouldLogin()) {
    window.location.href = '/staticViews/login.htm';
  } else {
    Sentry.setUser({
      email: getEmailFromSession(),
      id: getIdFromSession()
    });

    ReactDOM.render(
      <React.StrictMode>
        <Router history={history}>
          <App />
        </Router>
      </React.StrictMode>,
      document.getElementById('root')
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  }
}, 250);
