import { useState, useCallback } from 'react';
import qs from 'query-string';
import historyInstance from '../historyInstance';

export const getQueryStringValue = (
  key,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString, { parseBooleans: true });

  return values[key];
};

export const setQueryStringValue = (
  key,
  value,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString);

  if (!value) {
    delete values[key];
  } else {
    values[key] = value;
  }
  const newQsValue = qs.stringify(values);

  historyInstance.updateSearch(newQsValue);
  return newQsValue;
};

export const getModifiedSearch = (params: {
  [key: string]: string | undefined;
}) => {
  const values = qs.parse(window.location.search);

  Object.keys(params).forEach((p) => {
    if (!params[p]) {
      delete values[p];
    } else {
      values[p] = String(params[p]);
    }
  });
  return qs.stringify(values);
};

function useQueryString(key, initialValue) {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
}

export default useQueryString;
