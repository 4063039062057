import styled from 'styled-components';
import { injectColor } from '../../../theme';

export const MapItemStyled = styled.div`
  display: flex;
  margin-left: 10px;
  border-left: 2px solid ${injectColor('mapSeparatorLighter')};

  .tree-map__item-arrow {
    position: relative;
    margin-left: -2px;
    width: 40px;
    height: 38px;
    border-left: 2px solid ${injectColor('mapSeparatorLighter')};
    border-bottom: 2px solid ${injectColor('mapSeparatorLighter')};
    border-bottom-left-radius: 8px;

    .dot {
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${injectColor('mapSeparatorLighter')};
      top: 0;
      left: -5px;
      display: none;

      &.bottom {
        display: block;
        right: 0;
        bottom: -5px;
        top: auto;
        left: auto;
      }
    }
  }

  .tree-map__item-box {
    margin-top: 20px;
    width: 250px;
    font-family: Roboto, sans-serif;
    border-radius: 4px;
    color: ${injectColor('textDarker')};
    background-color: ${injectColor('bgLightDarkest')};
    padding-bottom: 12px;

    .tree-map__box-title {
      font-family: Roboto, sans-serif;
      padding: 8px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      font-size: 13px;
      font-weight: 500;
      background-color: ${injectColor('mapSeparatorLighter')};
    }

    .tree-map__box-line {
      padding: 12px 8px 0 8px;
      font-size: 13px;
    }
  }
`;
