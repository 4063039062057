import React, { useMemo } from 'react';
import { useCMSState } from 'c2-cms-plugin';

import Badge from '../components/Badge';

const New: React.FC<{ category?: number; article?: number }> = ({
  category,
  article
}) => {
  const { notification } = useCMSState();
  const { notificationsCategories, notificationsArticles } = notification;
  const isActive = useMemo(() => {
    if (category) {
      return notificationsCategories.indexOf(category) >= 0;
    }
    if (article) {
      return notificationsArticles.indexOf(article) >= 0;
    }
  }, [category, article, notificationsArticles, notificationsCategories]);

  return isActive ? <Badge color="primary">NEW</Badge> : <React.Fragment />;
};

export default New;
