import React from 'react';
import { Fetch, NotificationModalHOC, SessionStorage } from 'c2-common-ui';
import * as Sentry from '@sentry/react';

import { ReactComponent as Library } from '../../assets/library-32.svg';
import { ReactComponent as User } from '../../assets/user-32.svg';
import useQueryConfig from '../../hooks/useQueryConfig';
import useQueryString from '../../hooks/useQueryState';
import { getAllRoles } from '../../webApis';

import PreviewMenu from './PreviewMenu';
import SideNavBrand from './SideNavBrand';
import SideNavUserMenu from './SideNavUserMenu';

import {
  BottomMenu,
  SideNavItem,
  SideNavStyled,
  UserInfo
} from './SideNav.style';

const SideNav = () => {
  // Component States
  // eslint-disable-next-line no-unused-vars
  const [adminUrl, setAdminUrl] = React.useState('');
  const [isCyberCubeUser, setIsCyberCubeUser] = React.useState(false);
  const [roles, setRoles] = React.useState([]);

  const [mode] = useQueryString('mode', null);
  const { hideSidenav } = useQueryConfig();

  const configureAdminUrl = () => {
    let adminUrl = '';

    if (
      localStorage.getItem('devModeCyberCube') ||
      window.location.href.toString().indexOf('localhost') !== -1
    ) {
      setAdminUrl('http://localhost:9002');
    } else {
      const getAdminUrl = function (appsArr) {
        const adminUrlObj = appsArr.filter((app) => app.name === 'RBAC')[0];

        adminUrl = adminUrlObj && adminUrlObj.url;

        return adminUrl;
      };

      if (SessionStorage.getItem('apps')) {
        const appsArr = JSON.parse(SessionStorage.getItem('apps'));

        setAdminUrl(getAdminUrl(appsArr));
      } else {
        const host = window.location.host.split('.');

        host.shift();

        Fetch.get(SessionStorage.getItem('appConfig.urlApps'))
          .then((res) => {
            SessionStorage.setItem('apps', JSON.stringify(res));

            setAdminUrl(getAdminUrl(res));
          })
          .catch((err) => {
            Sentry.captureException(err);

            setAdminUrl('');
          });
      }
    }
  };

  const fetchRoles = React.useCallback(async () => {
    if (isCyberCubeUser && !roles.length) {
      try {
        const res = await getAllRoles();

        setRoles(res);
      } catch (err) {
        Sentry.captureException(err);

        // eslint-disable-next-line no-console
        console.log('error', err);
      }
    }
  }, [isCyberCubeUser, roles]);

  React.useEffect(() => {
    configureAdminUrl();

    fetchRoles();
  }, [fetchRoles]);

  React.useEffect(() => {
    const email = SessionStorage.getItem('email');

    setIsCyberCubeUser(email.indexOf('cybcube.com') >= 0);
  }, []);

  if (hideSidenav) {
    return null;
  }

  return (
    <SideNavStyled data-test-id="app-sidenav">
      <SideNavBrand />
      <SideNavItem active data-test-id="app-sidenav_library">
        <Library />
      </SideNavItem>

      <BottomMenu>
        {isCyberCubeUser && (
          <SideNavItem
            data-test-id="app-sidenav_preview"
            className="preview"
            active={mode}
          >
            <PreviewMenu roles={roles}>
              <div className="preview-label">PREVIEW</div>
            </PreviewMenu>
          </SideNavItem>
        )}

        <SideNavUserMenu>
          <SideNavItem clickable>
            <UserInfo data-test-id="app-sidenav_user-info">
              <User />
            </UserInfo>
          </SideNavItem>
        </SideNavUserMenu>
      </BottomMenu>
    </SideNavStyled>
  );
};

export default NotificationModalHOC(SideNav);
