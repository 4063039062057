import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Telemetry } from 'c2-common-ui';

import { BUTTON, CLICK, CMS } from '../../../variables/telemetryVariables';

import { FooterLink } from '../Footer.style';

const FooterTextLink = ({ value, text, icon, onClick, last }) => {
  const handleClick = useCallback(() => {
    if (onClick && value) {
      onClick(value);

      const clickEvent = `visit_${value}_page`;

      Telemetry.addUIEventMetric({
        action: CLICK,
        app: CMS,
        component: BUTTON,
        event: clickEvent,
        feature: CMS
      });
    }
  }, [onClick, value]);

  return (
    <>
      <FooterLink
        onClick={handleClick}
        data-test-id={`bottom-container_nav-${value}}`}
      >
        {icon} {text}
      </FooterLink>
      {!last && <span className="separator" />}
    </>
  );
};

FooterTextLink.propTypes = {
  icon: PropTypes.object,
  last: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  value: PropTypes.string
};

export default FooterTextLink;