import React from 'react';
import { Helmet } from 'react-helmet';
import { Notifications } from 'c2-cms-plugin';
import { Route, Switch } from 'react-router-dom';
import { SessionStorage } from 'c2-common-ui';
import * as Sentry from '@sentry/react';

import { env } from '../../utils';
import { ReadNDAProvider } from '../../services/NDAService';
import { setGAUserParameters } from '../../analytics';
import ContentService, { ContentContext } from '../../services/ContentService';
import Documentation from '../../pages/Documentation';
import historyInstance from '../../historyInstance';
import Home from '../../pages/Home';
import initCommonUI from '../../common-ui';
import SearchPage from '../../pages/Search';
import useContent from '../../hooks/useContent';
import useQueryConfig from '../../hooks/useQueryConfig';

import Input from '../Input';
import SideNav from '../SideNav';
import Spinner from '../Spinner';
import { NotFound, ServerError, Unauthorized } from '../Status';

const currentEnv = env();

const ContentProvider = () => {
  const { pdf } = useQueryConfig();
  const shouldLoadAuth =
    !pdf && window.location.href.indexOf('session.html') < 0;

  // Component States
  const [loading, setLoading] = React.useState(shouldLoadAuth);

  const contentService = React.useMemo(() => {
    const hostWithPort = window.location.host;
    const hostWithoutPort = window.location.hostname;
    const host = currentEnv.local ? hostWithPort : hostWithoutPort;
    const apiUrl = `${
      currentEnv.local || currentEnv.local_dev ? '' : 'api.'
    }${host}`;

    return new ContentService(`${window.location.protocol}//${apiUrl}`);
  }, []);

  const init = React.useCallback(async () => {
    setLoading(true);

    await initCommonUI();

    if (
      localStorage.getItem('devModeCyberCube') ||
      window.location.href.toString().indexOf('localhost') !== -1
    ) {
      setGAUserParameters({
        app: 'Local',
        tenantName: 'CyberCube',
        tenantId: '1'
      });
    } else {
      setGAUserParameters({
        tenantName: SessionStorage.getItem('tenantName'),
        tenantId: SessionStorage.getItem('tenantId')
      });
    }

    setLoading(false);
  }, []);

  const {
    accessGroups,
    documentation,
    loadRootCategories,
    loading: rootCatsLoading,
    roots
  } = useContent(historyInstance, contentService);

  React.useEffect(() => {
    const loadConfig = async () => {
      await init();
      await contentService.loadConfig();
      await loadRootCategories();
    };

    loadConfig().catch((err) => {
      Sentry.captureException(err);

      // eslint-disable-next-line no-console
      console.error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className="overlay-wrapper">
        <Spinner className="spinner" data-test-id="spinner" />
      </div>
    );
  }

  return (
    <ContentContext.Provider value={contentService}>
      <ReadNDAProvider>
        <Helmet
          titleTemplate="%s | CyberCube"
          defaultTitle="Documentation | CyberCube"
        />
        <SideNav />
        {!pdf && <Notifications includeNew />}
        <Switch>
          <Route path="/500" component={ServerError} />
          <Route path="/403" component={Unauthorized} />
          <Route path="/404" component={NotFound} />
          <Route exact path="/input" component={Input} />
          <Route exact path="/search" component={SearchPage} />
          <Route exact path="/:rootCategory/:category?/:article?">
            <Documentation documentationCategory={documentation} />
          </Route>
          <Route exact path="/">
            <Home
              accessGroups={accessGroups}
              roots={roots}
              loading={rootCatsLoading || loading}
              documentationCategory={documentation}
            />
          </Route>
        </Switch>
      </ReadNDAProvider>
    </ContentContext.Provider>
  );
};

export default ContentProvider;
