import axios from 'axios';
import { Session, SessionStorage } from 'c2-common-ui';

const { isIdTokenValid, isRefreshTokenPresent } = Session;

export const isUserLastActiveLessThan2Hrs = () => {
  const currentTime = Date.now();

  if (isNaN(parseInt(sessionStorage.getItem('userLastActivityTime') || ''))) {
    sessionStorage.setItem('userLastActivityTime', currentTime.toString());
  }

  return (
    currentTime -
      parseInt(sessionStorage.getItem('userLastActivityTime') || '') <
    7200000
  );
};

const getRefreshToken = async () => {
  const url = `https://${sessionStorage.getItem(
    'userpool_domain'
  )}/oauth2/token`;
  const params = new URLSearchParams();

  params.append('grant_type', 'refresh_token');
  params.append('client_id', sessionStorage.getItem('client_id') || '');
  params.append('refresh_token', sessionStorage.getItem('refresh_token') || '');
  const { data } = await axios.post(url, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
  });

  SessionStorage.setItems(
    ['access_token', 'id_token', 'expires_in', 'token_type'],
    [data.access_token, data.id_token, data.expires_in, data.token_type]
  );
};

const checkSession = () => {
  return new Promise((resolve, reject) => {
    if (!isUserLastActiveLessThan2Hrs()) {
      window.location.href = '/staticViews/logout.htm';

      reject();
    } else if (isIdTokenValid()) {
      resolve();
    } else if (isRefreshTokenPresent()) {
      getRefreshToken()
        .then(resolve)
        .catch(reject);
    } else {
      window.location.href = '/staticViews/login.htm';

      reject();
    }
  });
};

export default checkSession;
