import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';

import { ContentContext } from '../../services/ContentService';
import { copyright } from '../../utils';
import { ReactComponent as ContactUs } from '../../assets/contact-us.svg';
import { ReactComponent as Faq } from '../../assets/questions.svg';
import { ReactComponent as Glossary } from '../../assets/glossary.svg';
import { ReactComponent as Legal } from '../../assets/scale.svg';
import historyInstance from '../../historyInstance';
import useQueryConfig from '../../hooks/useQueryConfig';

import HubspotFormPopup from '../HubspotFormPopup';

import FooterTextLink from './FooterTextLink';
import {
  FooterContainer,
  FooterLink,
  FooterNav,
  FooterText
} from './Footer.style';

const getSearch = (id) => {
  const values = qs.parse(window.location.search);

  values.root = String(id);
  return qs.stringify(values);
};

const links = [
  { value: 'faq', text: 'FAQs', icon: <Faq /> },
  { value: 'glossary', text: 'Glossary', icon: <Glossary /> },
  { value: 'legal', text: 'Legal', icon: <Legal /> }
];

const Footer = ({
  absolutePositioning,
  article,
  documentationCategory,
  includeCopyright,
  onCategoryChange
}) => {
  const [form, setForm] = useState();

  const service = useContext(ContentContext);
  const { hideSidenav } = useQueryConfig();

  useEffect(() => {
    if (service?.config.contactUs?.id && !form) {
      setForm(service.config.contactUs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service?.config.contactUs, form]);

  const onLinkClick = useCallback(
    (value) => {
      if (documentationCategory) {
        historyInstance.push({
          pathname: `/${documentationCategory?.slug}/${value}`,
          search: getSearch(documentationCategory.id)
        });

        if (onCategoryChange) {
          onCategoryChange(documentationCategory);
        }
      }
    },
    [onCategoryChange, documentationCategory]
  );

  return (
    <FooterContainer
      absolutePositioning={absolutePositioning}
      data-test-id="bottom-container"
      hideSidenav={hideSidenav}
    >
      {includeCopyright && (<FooterText>{copyright}</FooterText>)}
      <FooterNav data-test-id="bottom-container_nav" article={article}>
        <div className="footer-links">
          {form && (
            <HubspotFormPopup
              id={form.id}
              portal={form.portal}
              label={form.label}
              clickElement={
                <FooterLink data-test-id={`bottom-container_nav-${form.label}`}>
                  <ContactUs /> Contact Us
                </FooterLink>
              }
            />
          )}

          {form && <span className="separator" />}
          {links.map((l, index) => (
            <FooterTextLink
              key={l.value}
              onClick={onLinkClick}
              last={index === links.length - 1}
              {...l}
            />
          ))}
        </div>
        <div className="version">
          Version: <b>{process.env.REACT_APP_VERSION}</b>
        </div>
      </FooterNav>
    </FooterContainer>
  );
};

Footer.propTypes = {
  absolutePositioning: PropTypes.bool,
  article: PropTypes.bool,
  documentationCategory: PropTypes.object,
  includeCopyright: PropTypes.bool,
  onCategoryChange: PropTypes.func
};

export default Footer;
