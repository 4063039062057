import styled from 'styled-components';
import { injectColor, injectSize } from 'theme';

export const DocumentationContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  background: ${injectColor('bgLight')};
  width: calc(
    100vw - ${(props) => (props.hideSidenav ? 0 : props.theme.sizes.sidenav)}px
  );
  flex-direction: column;

  .page-header {
    width: 100%;
  }

  .page-header__addons {
    > div:first-child {
      margin-top: 23px;
      ${(props) =>
    props.hideSidenav &&
        `
        margin-right: 130px;
      `}
    }
  }
`;

export const DocumentationContent = styled.div`
  display: flex;
  height: calc(
    100vh - ${injectSize('upper_header')}px - ${injectSize('upper_nav')}px -
      18px
  );
  .menu-placeholder {
    width: ${injectSize('sidemenu')}px;
    min-width: ${injectSize('sidemenu')}px;
  }
`;
