export const wait = async (timeMS: number = 1000) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeMS);
  });
};

export const env = () => {
  const hostname = window.location.host;

  return {
    local: hostname.indexOf('local') >= 0,
    local_dev: hostname.indexOf(':3000') >= 0, // temp
    dev: hostname.indexOf('dev07') >= 0, // temp
    atlas: hostname.indexOf('atlas.cybcube.com') >= 0, // temp
    prod: hostname.indexOf('docs.cybcube.com') >= 0 // temp
  };
};

export const sortOrder = (a: any, b: any) => a.order - b.order;
export const sortBy = (col: string) => (a: any, b: any) => a[col] - b[col];

export const copyright = `Copyright © ${new Date().getFullYear()} CyberCube. All rights reserved.`;

export const getUserParam = (token: string, param: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))[param];
  } catch (e) {
    return null;
  }
};

export const ie = () =>
// @ts-ignore

  !!window.MSInputMethodContext && !!document.documentMode;
