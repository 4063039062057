/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/interface-name-prefix */
import React from 'react';
import axios, { AxiosInstance } from 'axios';
import { sortOrder } from 'utils';
import { getQueryStringValue } from 'hooks/useQueryState';

import checkSession from './checkSession';
import { getConfigurations } from 'webApis';

export type IContactUs = {
  id: string;
  label: string;
  portal: string;
};

export interface ContentConfiguration {
  contactUs?: IContactUs;
}

export const getContentModeParam = (firstparam?: boolean) => {
  const mode = getQueryStringValue('mode', window.location.search);
  const preview = getQueryStringValue('preview', window.location.search);
  const modes = (typeof mode === 'object' ? mode : mode ? [mode] : []) || [];

  if (modes.length) {
    const query = modes.reduce((prev, curr) => prev + '&mode=' + curr, '');

    return (
      `${firstparam ? '?' : ''}${query}` +
      (preview ? `&preview=${preview}` : '')
    );
  }
  return firstparam ? '?' : '';
};

export const getRootCategoriesPath = () =>
  `/cms/categories${getContentModeParam(true)}`;

export const getSearchPath = (
  query: string,
  offset = 0,
  limit = 10,
  details = false
) =>
  `/cms/categories/search${getContentModeParam(true)}&query=${query}${
    offset >= 0 ? `&offset=${offset}` : ''
  }${limit >= 0 ? `&limit=${limit}` : ''}
  ${details ? `&details=${details}` : ''}`;

export const getDetailedCategoryPath = (category: string, rootId?: string) =>
  `/cms/categories/${category}${getContentModeParam(true)}${
    rootId ? '&root=' + rootId : ''
  }`;

export const getFeedbackStatsPath = (id: number) => `/cms/feedbacks/stats?article=${id}`;

export const getCreateFeedbackPath = () => '/cms/feedbacks';

export const getDetailedArticlePath = (
  category: string,
  article: string,
  rootId?: number
) =>
  `/cms/categories/${category}/articles/${article}?${
    rootId ? `root=${rootId}` : ''
  }${getContentModeParam()}`;

export const parseConfigurations = (configurations: IConfiguration[]) =>
  configurations.reduce<ContentConfiguration>(
    (curr, config) =>
      config.key ? { ...curr, [config.key]: config.value } : curr,
    {}
  ) as ContentConfiguration;

export const ContentContext = React.createContext<IContentService | undefined>(
  undefined
);

export interface IContent {
  id: number;
  title: string;
  description?: string;
  slug: string;
  order: number;
  category?: boolean;
  article?: boolean;
  updated_at?: string;
  created_at?: string;
}

export interface IConfiguration {
  key: string;
  value: any;
}

export interface IAccessGroup {
  id: number;
  title: string;
  type: string;
  label: string;
}

export interface ICategory extends IContent {
  access_group?: IAccessGroup;
  articles?: IDetailedCategory[];
  sub_categories?: IDetailedCategory[];
  description?: string;
}

export interface IDetailedCategory extends ICategory {
  articles?: IDetailedCategory[];
  sub_categories?: IDetailedCategory[];
  loading?: boolean;
}

export interface IUserData {
  tenantId: string;
  tenantName: string;
  firstName: string;
  lastName: string;
}

export interface RoleGroup {
  id: number;
  value: string;
  title?: string;
}

export interface IFeedback {
  id?: number;
  article: number;
  helpful: number;
  comment?: string;
  not_relevant?: boolean;
  not_informative?: boolean;
  not_accurate?: boolean;
}

export interface ISearchResult extends IContent {
  parentTitle?: string;
  catTitle: string;
  catSlug: string;
  catId: number;
  parentId?: number;
  parentSlug?: string;
  content?: string;
}

export interface IArticle extends IContent {
  allow_pdf?: boolean;
  pdf_link?: string;
  content: string;
  data?: any;
  group?: string;
  stats?: any;
}

export interface ISearchResponse {
  pagination: IPagination;
  data: ISearchResult[];
}

export interface IPagination {
  limit: number;
  offset: number;
  rowCount: number;
  pageCount: number;
}

export interface IContentService {
  api: AxiosInstance;
  sessionKeyForAuthToken: string;
  config: ContentConfiguration;
  articleCache: object;
  categoryCache: object;
  roles: RoleGroup[];

  createArticleFeedback: (feedback: IFeedback) => Promise<void>;
  fetchArticleStats: (id: number) => Promise<any>;
  fetchRootCategories: () => Promise<ICategory[]>;

  fetchSubCategoriesAndArticles: (
    categorySlug: string,
    rootId?: string
  ) => Promise<IDetailedCategory>;

  fetchArticle: (
    categorySlug: string,
    articleSlug: string,
    rootId?: number
  ) => Promise<IArticle>;

  searchArticles: (
    query: string,
    offset?: number,
    limit?: number,
    details?: boolean
  ) => Promise<ISearchResponse>;
  // fetchRoles: () => Promise<RoleGroup[]>;
  loadConfig: () => Promise<ContentConfiguration>;
}

class ContentService implements IContentService {
  api: AxiosInstance;
  config: ContentConfiguration;
  roles: RoleGroup[];
  retry?: boolean;
  sessionKeyForAuthToken: string;
  articleCache: Object;
  categoryCache: Object;

  constructor(
    baseURL: string,
    sessionKeyForAuthToken = 'id_token',
    api?: AxiosInstance
  ) {
    this.config = {};
    this.articleCache = {};
    this.categoryCache = {};
    this.roles = [];
    this.sessionKeyForAuthToken = sessionKeyForAuthToken;
    this.api = api || axios.create({ baseURL, ...this.getConfig() });
    this.api.interceptors.request.use((req) => {
      req.url = req.url?.replace('?&', '?');

      return req;
    });
    this.api.interceptors.response.use(
      (res) => res,
      (error) => {
        const originalRequest = error.config;

        if ((!error.response || [401, 403, 500].indexOf(error.response.status) >= 0)
          && !this.retry && originalRequest.url.indexOf('/refresh') < 0) {
          this.retry = true;

          return checkSession().then(() => {
            originalRequest.headers = this.getConfig().headers;

            return axios(originalRequest);
          });
        }

        if (error.response && error.response.status) {
          if (error.response.status.toString().indexOf('5') === 0) {
            window.location.href = '/500';
          } else if (error.response.status === 401) {
            window.location.href = '/staticViews/login.htm';
          } else if (error.response.status === 403) {
            window.location.href = '/403';
          } else if (error.response.status === 404) {
            window.location.href = '/404';
          }
        }

        this.retry = false;

        return error;
      }
    );
  }

  getConfig = () => ({
    headers: {
      authorization: sessionStorage.getItem(this.sessionKeyForAuthToken)
    }
  });

  createArticleFeedback(feedback: IFeedback) {
    return this.api
      .post<void>(getCreateFeedbackPath(), feedback, this.getConfig())
      .then((res) => res.data);
  }

  fetchArticleStats(id: number): Promise<any> {
    return this.api
      .get<IArticle>(getFeedbackStatsPath(id), this.getConfig())
      .then((res) => res.data);
  }

  fetchArticle(
    categorySlug: string,
    articleSlug: string,
    rootId?: number
  ): Promise<IArticle> {
    return this.api
      .get<IArticle>(
        getDetailedArticlePath(categorySlug, articleSlug, rootId),
        this.getConfig()
      )
      .then((res) => res.data);
  }

  fetchRootCategories(): Promise<ICategory[]> {
    return this.api
      .get<ICategory[]>(getRootCategoriesPath(), this.getConfig())
      .then((res) => (res.data ? res.data.sort(sortOrder) : []));
  }

  fetchSubCategoriesAndArticles(
    categorySlug: string,
    rootId?: string
  ): Promise<IDetailedCategory> {
    return this.api
      .get<IDetailedCategory>(
        getDetailedCategoryPath(categorySlug, rootId),
        this.getConfig()
      )
      .then((res) => res.data);
  }

  searchArticles(
    query: string,
    offset?: number,
    limit?: number,
    details?: boolean
  ): Promise<ISearchResponse> {
    return this.api
      .get<{ body: ISearchResponse }>(
        getSearchPath(query, offset, limit, details),
        this.getConfig()
      )
      .then((res) => res.data.body);
  }

  loadConfig(): Promise<ContentConfiguration> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    return getConfigurations()
      .then((res) => {
        const config = parseConfigurations(res);

        Object.assign(that.config, config);
        return config;
      });
  }
}

export default ContentService;
