import styled from 'styled-components';
import { injectColor } from '../../theme';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  button {
    background: ${injectColor('main')};
    color: white;
    font-size: 13px;
    min-width: 70px;
    height: 30px;
    padding: 6px 12px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &.link {
      background: none;
      color: #0b74a3;

      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
`;

export const PopupContainer = styled.div`
  width: 30vw;
  min-width: 200px;
  max-width: 200px;
  font-size: 14px;
  position: absolute;
  bottom: 10px;
  left: 110%;
  z-index: 3000;
  padding: 20px;
  background: white;
  color: ${injectColor('textDark')};
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.05);

  > a {
    width: 100%;
    text-align: left;
    float: left;
    padding-left: 8px;
    margin-top: 12px;
  }

  > div:not(.options-container) {
    padding-left: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &.heading {
      font-weight: 500;
      padding-left: 0;
    }
  }

  &&& > .preview-mode {
    padding-left: 0;
  }

  .options-container {
    overflow: auto;
    width: 100%;
    max-height: 300px;
    display: flex;
    flex-direction: column;
  }

  .logout-button {
    text-align: left;
    height: 34px;
    margin-left: 7px;
  }

  .logout-button:hover {
    background-color: transparent;
  }
`;

export const PopupStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: inherit;
  height: inherit;

  svg,
  svg use {
    pointer-events: none;
  }
`;
