import { ThemeProps } from 'styled-components';

export interface ITheme {
  sizes: {
    footer: number;
    sidenav: number;
    sidemenu: number;
    upper_header: number;
    upper_header_width: number;
    upper_nav: number;
  };

  colors: {
    main: string;
    mainDarker: string;
    mainLight: string;
    separator: string;
    mapSeparator: string;
    mapHeader: string;
    mapSeparatorLighter: string;
    textDark: string;
    textDarker: string;
    textFooter: string;
    textLabel: string;
    bgLight: string;
    bgLightAvg: string;
    bgLightDarker: string;
    bgLightDarkest: string;
    bgDarkHover: string;
    bgDark: string;
    bgDarker: string;
    bgTooltip: string;
    bgFooter: string;
    bgFooterSeparator: string;
    RGBALight: string;
    RGBALightest: string;
    RGBADark: string;
  };
}

const theme: ITheme = {
  sizes: {
    footer: 67,
    sidenav: 80,
    sidemenu: 240,
    upper_header: 82,
    upper_header_width: 85,
    upper_nav: 48
  },
  colors: {
    main: '#0e8fc9',
    mainDarker: '#0b74a3',
    mainLight: '#e4f1f8',

    separator: '#e3e3e3',
    mapSeparator: '#a3a3a3',
    mapHeader: '#d9d9d9',
    mapSeparatorLighter: '#cfcfcf',

    textDark: '#525252',
    textDarker: '#383838',
    textFooter: '#777777',
    textLabel: '#6a6a6a',

    bgLight: '#fafafa',
    bgLightDarker: '#f7f7f7',
    bgLightDarkest: '#f2f2f2',
    bgLightAvg: '#ebebeb',
    bgDarkHover: '#575d64',
    bgDark: '#373b40',
    bgDarker: '#292e33',
    bgTooltip: '#2d2e2e',
    bgFooter: '#f8f8f8',
    bgFooterSeparator: '#e8eaeb',

    RGBADark: 'rgba(255, 255, 255, 0.1)',
    RGBALight: 'rgba(0, 0, 0, 0.1)',
    RGBALightest: 'rgba(0, 0, 0, 0.05)'
  }
};

type StyledProps<T = any> = T & ThemeProps<ITheme>;

export const injectColor = (name: keyof ITheme['colors']) => (
  props: StyledProps
) => props.theme.colors[name];

export const injectSize = (name: keyof ITheme['sizes']) => (
  props: StyledProps
) => props.theme.sizes[name];

export default theme;
