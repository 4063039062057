import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { StatusPageStyled } from './Status.style.js';
import { HomeContainer } from '../../pages/Home/Home.style';
import HTTP404Page from './HTTP404Page';
import PropTypes from 'prop-types';

const Status = ({ code, buttonText, ...props }) => {
  const history = useHistory();
  const onClick = useCallback(
    () => {
      history.replace({ pathname: '/', search: window.location.search });
    },
    [history]
  );

  if (code === 404) {
    return <HTTP404Page buttonText={buttonText} onClick={onClick}>{props.children}</HTTP404Page>;
  }

  return (
    <>
      <HomeContainer hideSidenav={false} data-test-id="home-container">
        <StatusPageStyled data-test-id="app-error-page">
          <div className="status-code" data-test-id="app-error-page_code">
            {code}
          </div>
          {props.children}
          {buttonText && (
            <button onClick={onClick} data-test-id="app-error-page_btn">
              {buttonText}
            </button>
          )}
        </StatusPageStyled>
      </HomeContainer>
    </>
  );
};

export const NotFound = () => (
  <Status code={404} buttonText="Go back home">
    We apologize for the inconvenience, the page you are looking for does not exist.
    <br/>
    Try searching for another article.
  </Status>
);

export const Unauthorized = () => (
  <Status code={403} buttonText="Go home">
    You are not authorized to see this resource.
  </Status>
);

export const ServerError = () => (
  <Status code={500} buttonText="Go home">
    Something went wrong, contact{' '}
    <a href="mailto:support@cybcube.com">support@cybcube.com</a>.
  </Status>
);

Status.propTypes = {
  buttonText: PropTypes.string,
  children: PropTypes.node,
  code: PropTypes.oneOf([404, 403, 500]).isRequired
};

export default Status;
