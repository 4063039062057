import styled from 'styled-components';
import { injectColor } from '../../theme';

export const StatusPageStyled = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${injectColor('textDark')};
  font-size: 16px;

  button {
    color: ${injectColor('textDark')};
    cursor: pointer;
    margin-top: 24px;
    padding: 12px 16px;
    background-color: white;
    border: 1px solid ${injectColor('separator')};
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    font-weight: 500;
  }

  .status-code {
    font-size: 72px;
    font-weight: bold;
    margin-bottom: 18px;
  }
`;