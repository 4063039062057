export const setHTMLInputValue = (input, value) => {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    HTMLInputElement.prototype,
    'value'
  )?.set;

  if (input && nativeInputValueSetter) {
    nativeInputValueSetter.call(input, value);
    const event = new Event('input', { bubbles: true });

    input.dispatchEvent(event);
  }
};
