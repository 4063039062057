import styled from 'styled-components';

import { injectColor } from 'theme';

export const RootCategoryWrapper = styled.div`
  position: relative;
  padding: 0;
  border-radius: 6px;
  background: white;
  min-height: 200px;
  max-height: 200px;
`;

export const RootCategoryStyled = styled.div`
  position: absolute;
  color: #5a5a5a;
  background: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  overflow: hidden;
  ${props =>
    props.open && `
      z-index: ${100 - (props.order ?? 0)};
      transition: max-height, box-shadow 0.3s ease-in-out;
      max-height: 300px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.35);
    `}
`;

export const RootCategoryContainer = styled.div`
  .badge {
    margin-left: 8px;
  }

  ul {
    padding: 15px 0 0 0;
    padding-left: 20px;
    margin: 4px 0;
    list-style: none;

    > a {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: Roboto, sans-serif;
      padding-bottom: 10px;
      text-align: left;
      color: ${injectColor('textDark')};

      &:hover {
        cursor: pointer;
        color: ${injectColor('mainDarker')};
      }
    }
  }
`;

export const RootCategoryHeader = styled.div`
  padding: 14px 20px 16px 20px;
  font-weight: 500;
  border-bottom: 1px solid ${injectColor('bgLightDarkest')};
  color: ${injectColor('textDark')};
  font-size: 16px;
  text-align: left;
  display: flex;
  align-items: center;

  svg {
    margin-right: 12px;
    g > path:not(:first-child) {
      fill: #e38949;
    }
  }
`;

export const MoreItems = styled.div`
  position: absolute;
  left: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  bottom: 0;
  padding: 14px 0;
  background-color: white;
  width: 100%;
  border-top: 1px solid ${injectColor('bgLightDarkest')};
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 4px;
  }

  &:hover {
    cursor: pointer;
    svg {
      path {
        transition: fill 0.3s ease-in-out;
        fill: #0e8fc9;
      }
    }
  }
`;
