import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as BackButton } from 'assets/back_button.svg';
import { AddonsContainer, HeaderStyled } from './Header.style';
import historyInstance from '../../historyInstance';
import PropTypes from 'prop-types';

const Header = ({ addons, backType, title }) => {
  const back = useCallback(
    (e) => {
      if (backType === 'back') {
        e.preventDefault();
        historyInstance.go(-1);
      }
    },
    [backType]
  );

  return (
    <HeaderStyled className="page-header" data-test-id="page-header">
      {backType && (
        <Link
          onClick={back}
          to={{
            pathname: '/',
            search: window.location.search
          }}
          data-test-id="page-header_back"
        >
          <BackButton className="arrow-left" />
        </Link>
      )}
      {title && <h1>{title}</h1>}
      {addons && (
        <AddonsContainer
          className="page-header__addons"
          data-test-id="page-header_addons"
        >
          {addons}
        </AddonsContainer>
      )}
    </HeaderStyled>
  );
};

Header.propTypes = {
  addons: PropTypes.node,
  backType: PropTypes.oneOf(['back', 'home']),
  title: PropTypes.string
};

export default Header;
