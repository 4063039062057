import React from 'react';
import PropTypes from 'prop-types';
import { SessionStorage, Telemetry } from 'c2-common-ui';

import useQueryString from '../../../hooks/useQueryState';
import { BUTTON, CLICK, CMS } from '../../../variables/telemetryVariables';

import Checkbox from '../../Checkbox';
import Popup from '../../Popup';
import { ButtonContainer } from '../../Popup/Popup.style.js';

const PreviewMenu = ({ children, roles }) => {
  const [rolesChecked, setRolesChecked] = React.useState({});

  const [mode, setMode] = useQueryString('mode', null);
  const [previewFlag, setPreviewFlag] = useQueryString('preview', false);

  const handleApply = React.useCallback(async () => {
    const actives = Object.keys(rolesChecked).filter((k) => rolesChecked[k]);

    if (actives.length) {
      setMode(actives);

      let checkedEvent = 'preview_as';

      actives.forEach(num => {
        const item = roles.find(x => x.id.toString() === num);

        if (item.title) {
          checkedEvent += item.title;
        }
      });

      let formattedEvent = checkedEvent
        .replace(/\d+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map((word) => word.toLowerCase())
        .join('_');

      previewFlag ? formattedEvent += '_exclude_drafts' : formattedEvent += '_include_drafts';

      await Telemetry.addUIEventMetric({
        action: CLICK,
        app: CMS,
        component: BUTTON,
        event: formattedEvent,
        feature: CMS
      });

      window.location.reload();
    } else {
      setMode(null);
    }
  }, [previewFlag, roles, rolesChecked, setMode]);

  const handleExitPreview = React.useCallback(() => {
    setMode(null);
    setPreviewFlag(false);

    window.location.reload();
  }, [setMode, setPreviewFlag]);

  const handlePreviewFlag = React.useCallback(() => {
    setPreviewFlag(!previewFlag);
  }, [previewFlag, setPreviewFlag]);

  const handleTogglePreview = React.useCallback(
    (event) => {
      setRolesChecked({
        ...rolesChecked,
        [event.target.name]: !rolesChecked[event.target.name]
      });
    },
    [rolesChecked]
  );

  React.useEffect(() => {
    if (roles) {
      const userRoles = JSON.parse(SessionStorage.getItem('rolesWithProducts'));

      setRolesChecked(
        roles.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.id]: mode
              ? mode.indexOf(curr.id.toString()) >= 0
              : userRoles.indexOf(curr.value) >= 0
          }),
          {}
        )
      );
    }
  }, [roles, mode]);

  return (
    <Popup
      content={
        <React.Fragment>
          <div className="preview-mode">
            <Checkbox
              name="preview-flag"
              onChange={handlePreviewFlag}
              value={previewFlag}
            >
              Include drafts
            </Checkbox>
          </div>
          <div className="preview-heading">Select roles to preview</div>
          <div className="options-container">
            {roles && roles.map((role) => (
              <Checkbox
                key={role.id}
                name={role.id.toString()}
                onChange={handleTogglePreview}
                value={rolesChecked[role.id]}
              >
                {role.title}
              </Checkbox>
            ))}
          </div>
          <ButtonContainer>
            <button className="link" onClick={handleExitPreview} type="button">
              Exit preview
            </button>
            <button onClick={handleApply} type="button">
              Apply
            </button>
          </ButtonContainer>
        </React.Fragment>
      }
    >
      {children}
    </Popup>
  );
};

PreviewMenu.propTypes = {
  children: PropTypes.element,
  roles: PropTypes.array
};

export default PreviewMenu;
