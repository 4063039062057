import styled from 'styled-components';
import { injectColor } from '../../theme';

export const FormWrapper = styled.div`
  height: 100%;
  width: 100%;
  max-height: 50vh;
  display: flex;
  justify-content: center;
  min-width: 400px;
  > div {
    width: 100%;
  }
  .submitted-message {
    text-align: center;
    p {
      font-size: 16px;
      color: ${injectColor('textDark')};
    }
  }
  .actions {
    opacity: 0;
    input {
      opacity: 0;
    }
  }
  .hs-form-field {
    input[type="file"] {
      margin-bottom: 30px;
    }

    textarea.hs-input,
    input[type="text"].hs-input {
      min-height: 120px;
      border: 1px solid #dee0e0;
      margin: 8px 0;
      font-size: 14px;
      outline: none;
      padding: 16px;
      width: calc(100% - 32px);
    }

    input[type="text"].hs-input {
      min-height: 40px;
      padding: 12px;
      width: calc(100% - 24px);
    }
    label span {
      color: #525252;
      font-size: 13px;
      font-family: Roboto, sans-serif;
    }
    .hs-field-desc {
      color: #a3a3a3;
      font-size: 14px;
      margin-top: 4px;
      margin-bottom: 8px;
    }
    legend {
    }
    .input {
      ul {
        padding-left: 0;

        list-style: none;
        margin: 12px 0 6px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        li {
          &:not(:first-child) {
            margin-left: 36px;
          }
          label {
            display: flex;
            align-items: center;
            cursor: pointer;
            span {
              font-size: 14px;
              color: #383838;
            }
            input {
              position: relative;
              background-color: white;
              -webkit-appearance: inherit;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              border: 1px solid #c5c6c7;
              &:focus,
              &:active,
              &:checked {
                border: 1px solid #00a9e0;

                outline: none;
              }
              &:checked::before {
                content: "";
                position: absolute;
                left: 4px;
                top: 4px;
                margin: 0 auto;
                width: 10px;
                height: 10px;
                background: #00a9e0;
                border-radius: 50%;
              }
            }
            input + span {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
`;
