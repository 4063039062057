import React from 'react';
import {
  Button,
  LoadingSpinnerMd,
  ModalMd,
  NotificationCenter,
  Utils
} from 'c2-common-ui';
import * as Sentry from '@sentry/react';

import CurrentNdaText from 'assets/CurrentNdaText';
import {
  CURRENT_NDA_VERSION,
  ReadNDAContext,
  UpdateReadNDAContext
} from 'services/NDAService';
import { setUserProperty } from 'webApis/users';

import './styles.scss';

const NDAModal = () => {
  // Component States
  const [acceptButtonLocked, setAcceptButtonLocked] = React.useState(false);
  const [dateAcceptedOldNDA, setDateAcceptedOldNDA] = React.useState('');
  const [notifications, setNotifications] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [versionUserAccepted, setVersionUserAccepted] = React.useState('');

  const readNDA = React.useContext(ReadNDAContext);
  const setReadNDA = React.useContext(UpdateReadNDAContext);

  const handleAcceptNDA = React.useCallback(() => {
    setAcceptButtonLocked(true);

    setUserProperty({ property: { name: 'nda' } })
      .then(() => {
        sessionStorage.setItem(
          'read_nda_details',
          JSON.stringify({
            dateSigned: new Date().getTime(),
            version: CURRENT_NDA_VERSION
          })
        );

        setReadNDA(true);
      })
      .catch((err) => {
        Sentry.captureException(err);

        const
          notificationsClone = Utils.deepClone(notifications),
          keys = Object.keys(notificationsClone);

        if (keys.length === 0) {
          notificationsClone[0] = {
            type: 'error',
            message: 'Something went wrong. Please try again later.'
          };
        } else {
          notificationsClone[parseInt(keys[keys.length - 1]) + 1] = {
            type: 'error',
            message: 'Something went wrong. Please try again later.'
          };
        }

        setNotifications(notificationsClone);
        setAcceptButtonLocked(false);

        sessionStorage.setItem(
          'read_nda_details',
          JSON.stringify({
            dateSigned: new Date().getTime(),
            version: CURRENT_NDA_VERSION
          })
        );

        setReadNDA(true);

        // eslint-disable-next-line no-console
        console.error();
      });
  }, [setReadNDA, notifications]);

  const handleDeleteNotification = React.useCallback(
    (index) => {
      const notificationsClone = Utils.deepClone(notifications);

      delete notificationsClone[index];

      setNotifications(notificationsClone);
    },
    [notifications]
  );

  const formatDate = React.useCallback((date) => {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }, []);

  React.useEffect(() => {
    const readNDADetails = JSON.parse(
      sessionStorage.getItem('read_nda_details')
    );

    if (!readNDADetails) {
      setDateAcceptedOldNDA('');
      setVersionUserAccepted('');
    } else {
      const { version, dateSigned } = readNDADetails;

      if (version !== CURRENT_NDA_VERSION) {
        setDateAcceptedOldNDA(formatDate(new Date(parseInt(dateSigned))));
        setReadNDA(false);
        setVersionUserAccepted(version);
      }
    }

    setLoading(false);
  }, [formatDate, setReadNDA, setVersionUserAccepted]);

  return (
    <React.Fragment>
      <NotificationCenter
        notifications={notifications}
        onHandleDeleteNotification={handleDeleteNotification}
      />
      <ModalMd
        open={!readNDA}
        title={`Non-disclosure Agreement v${CURRENT_NDA_VERSION}`}
      >
        {loading && <LoadingSpinnerMd />}
        {!loading && (
          <>
            <div
              className="modal-body"
              style={{
                height: '330px',
                paddingLeft: '0px',
                paddingRight: '0px'
              }}
            >
              <div className="nda-text">
                {dateAcceptedOldNDA && versionUserAccepted && (
                  <p className="accepted-date">
                    Last version of NDA {versionUserAccepted} was accepted on{' '}
                    {dateAcceptedOldNDA}
                  </p>
                )}
                <CurrentNdaText />
              </div>
            </div>
            <div className="modal-footer">
              <Button
                classes={'primary'}
                locked={acceptButtonLocked}
                onClick={handleAcceptNDA}
                style={{
                  position: 'relative',
                  height: '40px',
                  width: '90px'
                }}
              >
                Accept
              </Button>
            </div>
          </>
        )}
      </ModalMd>
    </React.Fragment>
  );
};

export default NDAModal;
