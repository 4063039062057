// Needed to fix console errors with Hubspot Form
// @ts-ignore
window.jQuery =
  // @ts-ignore
  window.jQuery ||
  (() => ({
    // these are all methods required by HubSpot
    change: () => {},
    trigger: () => {},
    serializeArray: () => {}
  }));
