import React from 'react';
import { CheckboxContainer } from './Checkbox.style.js';
import PropTypes from 'prop-types';

const Checkbox = ({ children, name, onChange, value }) => {
  return (
    <CheckboxContainer>
      {children}
      <input type="checkbox" name={name} checked={value} onChange={onChange} />
      <span className="checkmark" />
    </CheckboxContainer>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool
};

export default Checkbox;
