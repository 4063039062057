import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import {
  ModalStyled,
  ModalHeader,
  ModalBody,
  ModalFooter,
  OverlayContainer
} from './Modal.style';

interface ModalProps {
  open: boolean;
  buttons: React.ReactNode;
  children: React.ReactNode;
  title: string;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({
  open,
  buttons,
  children,
  title,
  onClose
}) => {
  const handleClose = useCallback(
    () => {
      if (onClose) {
        onClose();
      }
    },
    [onClose]
  );

  const preventPropagation = (e) => {
    e.stopPropagation();
  };

  //TODO: Look at https://stackoverflow.com/questions/59756648/add-classes-to-styled-component to give Overlay Container a new className
  return open ? (
    ReactDOM.createPortal(
      <OverlayContainer onClick={handleClose}>
        <ModalStyled
          onClick={preventPropagation}
          data-test-id="modal-container"
        >
          <ModalHeader data-test-id="modal-header">{title}</ModalHeader>
          <ModalBody data-test-id="modal-body">{children}</ModalBody>
          <ModalFooter data-test-id="modal-footer">{buttons}</ModalFooter>
        </ModalStyled>
      </OverlayContainer>,
      document.body
    )
  ) : (
    <span />
  );
};

export default Modal;