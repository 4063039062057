/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { Link, useHistory } from 'react-router-dom';
import historyInstance from 'historyInstance';
import { getModifiedSearch, setQueryStringValue } from 'hooks/useQueryState';
import Autosuggest from 'react-autosuggest';
import Ellipsis from 'components/Ellipsis';
import { Telemetry } from 'c2-common-ui';
import { Input, useSearch } from 'c2-cms-plugin';
import {
  CLICK,
  CLICK_ARTICLE,
  CMS,
  DROPDOWN,
  SEARCH,
  SEARCH_KEYWORD
} from '../../variables/telemetryVariables';
import {
  SearchStyled,
  SuggestionStyled,
  SuggestionTitleStyled
} from './Search.style';
import New from '../New';
import PropTypes from 'prop-types';

const getSuggestionValue = (suggestion) => suggestion.id;

const renderSectionTitle = (suggestion) => {
  return <SuggestionTitleStyled>{suggestion.title}</SuggestionTitleStyled>;
};

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => {
  const group =
    suggestion.group || (suggestion.parentTitle ? suggestion.catTitle : '');
  const text = `${group ? group + ' - ' : ''}${suggestion.title}`;

  return (
    <SuggestionStyled data-test-id="page-header_search-item">
      <Ellipsis
        data-test-id="page-header_search-title"
        text={text}
        width={250}
      />
      <New article={suggestion.id} />
    </SuggestionStyled>
  );
};

function renderSuggestionsContainer(
  { containerProps, children, query },
  total,
  pagination,
  value,
  customHeight
) {
  return (
    <div {...containerProps}>
      <SimpleBar style={{ maxHeight: customHeight !== undefined ? customHeight : 400 }}>{children}</SimpleBar>
      {pagination.rowCount > total && children && children.length && (
        <Link
          className="bottom-link"
          to={`/search${window.location.search}`}
          onMouseDown={(e) => {
            setQueryStringValue('query', value);
          }}
        >
          Show all {pagination.rowCount} results
        </Link>
      )}
    </div>
  );
}

const Search = ({ height }) => {
  const history = useHistory();

  const [currentSuggestions, setCurrentSuggestions] = useState();

  const {
    value,
    onChange: onSearchChange,
    setSize,
    groups,
    items,
    total,
    paging,
    loading
  } = useSearch();

  const [suggestion, setSuggestion] = useState();

  const goToArticle = useCallback(
    (item) => {
      const titleUnderscored = item.catSlug.replaceAll('-', '_');

      history.push({
        pathname: `/${item.parentSlug ? item.parentSlug + '/' : ''}${
          item.catSlug
        }/${item.slug}`,
        search: getModifiedSearch({
          root: String(item.parentId || item.catId)
        })
      });

      Telemetry.addUIEventMetric({
        action: CLICK,
        app: CMS,
        component: DROPDOWN,
        event: `${CLICK_ARTICLE}_${titleUnderscored}`,
        feature: SEARCH
      });
    },
    [history]
  );

  const onChange = useCallback(
    (event, { newValue, method }) => {
      if (method === 'type') {
        onSearchChange({
          ...event,
          target: { ...event.target, value: newValue }
        });
      }
    },
    [onSearchChange]
  );

  const onSelect = useCallback(
    (e, { suggestion }) => {
      e.stopPropagation();
      if (suggestion) {
        goToArticle(suggestion);
      }
    },
    [goToArticle]
  );

  const onFetch = useCallback(
    ({ value, reason }) => {
      if (reason === 'input-focused' && value) {
        setSize(1);
      }
    },
    [setSize]
  );

  const onClear = useCallback(() => {
    setCurrentSuggestions([]);
  }, []);

  const onKeyDown = useCallback(
    (e) => {
      if (!suggestion && e.key === 'Enter') {
        setQueryStringValue('query', value);
        historyInstance.pushWithQuery('/search', window.location.search);

        Telemetry.addUIEventMetric({
          action: SEARCH,
          app: CMS,
          component: SEARCH,
          event: SEARCH_KEYWORD,
          feature: CMS
        });
      }
    },
    [suggestion, value]
  );

  useEffect(() => {
    if (groups && items) {
      setCurrentSuggestions(
        Object.keys(items).map((group) => ({
          title: group,
          suggestions: items[group]
        }))
      );
    }
  }, [groups, items]);

  const inputProps = {
    autoUpdate: true,
    autoFocus: true,
    placeholder: 'Search articles',
    value,
    onChange,
    onKeyDown
  };

  const renderInputComponent = useCallback(
    (inputProps) => (
      <Input
        {...inputProps}
        bordered
        loading={loading}
        addon="search"
        onClear={() => inputProps.onChange({ target: { value: '' } })}
      />
    ),
    [loading]
  );

  const renderContainer = useCallback(
    (containerProps) =>
      renderSuggestionsContainer(containerProps, total, paging, value, height),
    [height, paging, total, value]
  );

  const getSectionSuggestions = (suggestion) => suggestion.suggestions;

  return (
    <SearchStyled data-test-id="page-header_search-autosuggest">
      <Autosuggest
        suggestions={currentSuggestions || []}
        onSuggestionSelected={onSelect}
        onSuggestionHighlighted={(sugg) => setSuggestion(sugg.suggestion)}
        onSuggestionsFetchRequested={onFetch}
        onSuggestionsClearRequested={onClear}
        getSuggestionValue={getSuggestionValue}
        multiSection={true}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderContainer}
        getSectionSuggestions={getSectionSuggestions}
        renderSectionTitle={renderSectionTitle}
        renderInputComponent={renderInputComponent}
        inputProps={inputProps}
      />
    </SearchStyled>
  );
};

Search.propTypes = {
  height: PropTypes.number
};

export default Search;
