import React from 'react';
import styled from 'styled-components';
import { injectColor, injectSize } from '../../theme';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export const SideMenuContainer = styled.div`
  position: relative;
  width: ${injectSize('sidemenu')}px;
  min-width: ${injectSize('sidemenu')}px;
  z-index: 1;
  border-right: 1px solid ${injectColor('separator')};
  background: ${injectColor('bgLight')};
  .badge {
    margin-left: 0;
    margin-right: 12px;
    display: flex;
    align-items: center;
  }
  .simplebar-content-wrapper {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  h3 {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 16px;
    margin: 0 0 12px;
    color: ${injectColor('main')};
    text-transform: uppercase;
    box-shadow: 0 1px 1px 0 ${injectColor('separator')};
    border-right: 1px solid ${injectColor('separator')};
    img {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
  .group {
    text-align: left;
    display: block;
    font-weight: 600;
    font-size: 14px;
    padding: 8px 8px 8px 40px;
    background: ${injectColor('bgLight')};
  }
  hr {
    height: 1px;
    box-shadow: 0 1px 1px 0 ${injectColor('RGBALight')};
  }
`;

export const SideMenuArticlesContainer = styled.div`
  background: ${injectColor('bgLight')};
  color: ${injectColor('textDark')};
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

  ${({ hasChildren, active }) =>
    active &&
    hasChildren &&
    `
      padding-top: 8px;
      padding-bottom: 8px;
      `}
  ${({ active }) =>
    active &&
    `
      max-height: 3000px;
      transition: max-height 1s ease-in-out;
      visibility: visible;
    `}
`;

export const SideMenuCategoryContainer = styled.div`
  width: 100%;
  height: auto;
`;

export const MenuItem = styled(({ active, subItem, ...rest }) => (
  <Link {...rest} />
))`
  position: relative;
  font-family: Roboto, sans-serif;
  padding: 12px 12px 12px 30px;
  font-size: 14px;

  user-select: none;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  font-weight: 400;
  color: ${injectColor('textDark')};

  .ellipsis-container {
    flex: 1;
  }

  ${({ active, subItem, theme }) =>
    active &&
    `
      outline: none;
      font-weight: 500;
      ${
  subItem &&
        `
        background-color: ${theme.colors.main};
        color: white;
      `
}
    `}

  ${({ subItem }) =>
    subItem &&
    `
      font-size: 14px;
      padding-left: 40px;
    `}

  span.group ~ & {
    padding-left: 56px;
  }

  .triangle {
    position: absolute;
    left: 16px;
    top: 16px;
    transform: rotate(90deg);
    ${({ active }) =>
    active &&
      `
        transform: rotate(180deg);
    `}
  }

  &:hover {
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;

    ${({ active, theme }) =>
    !active &&
      `
      background-color: ${theme.colors.bgLightDarkest};
      color: ${theme.colors.textDark};
    `};
  }
`;

MenuItem.propTypes = {
  active: PropTypes.bool,
  subItem: PropTypes.bool
};