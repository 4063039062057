import styled from 'styled-components';
import { injectColor } from '../../theme';

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-width: 100%;
  height: 100%;
  z-index: 1;
  .image > img {
    max-height: none;
    width: auto;
    margin: 0 auto;
  }
  .img-shimmer {
    margin: 32px auto;
  }
  .preview-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5000;
    background: rgba(0, 0, 0, 0.4);
    .preview {
      position: relative;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      max-height: calc(100vh - 150px);
      max-width: calc(100vw - 150px);
      min-width: 50vw;
      user-select: none;
      box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.4);

      .close {
        transform: scale(1.5);
        position: absolute;
        cursor: pointer;
        right: -20px;
        top: -20px;

        &:hover {
          g > use {
            stroke-width: 3px;
            transition: all 0.2s ease;
            fill: rgba(255, 255, 255, 0.9);
          }
        }
      }
      img {
        max-height: calc(100vh - 190px);
        max-width: calc(100vw - 190px);
        height: auto;
      }
    }
  }
`;

export const ArticleContainer = styled.div`
  position: relative;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  text-align: left;
  width: 100%;
  height: 100%;
  ${(props) =>
    !props.onlyArticle &&
    `
  width: calc(
    100vw - ${props.theme.sizes.sidemenu}px -
      ${props.hideSidenav ? 0 : props.theme.sizes.sidenav}px
  );
`}

  overflow: auto;

  .with-panel {
    display: flex;
    background: ${injectColor('bgLight')};
    align-items: flex-start;

    .article {
      display: flex;
      flex-direction: column;
      position: relative;
      min-height: calc(100vh - 148px);
      background-color: white;
      min-width: 600px;
      max-width: 1080px;
      flex: 3;
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      &.no-pdf {
        .ck-content {
          padding-top: 40px;
        }
        .ck-content div > h1:first-child {
          max-width: 100%;
        }
      }

      .article-footer {
        flex: 2;
        display: flex;
        padding: 16px 0;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
      }

      .swagger-ui {
        padding-top: 25px;
      }
    }
    .article-controls {
      height: calc(100vh - 148px);
      min-height: calc(100vh - 148px);
      display: flex;
      align-items: center;
      min-width: 280px;
    }
  }

  figure.table {
    -ms-overflow-style: scrollbar;
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background-color: #f8f8f8;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 3px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  &.treeMap {
    background-color: white;
    max-width: none;
    .article {
      box-shadow: none;
      max-width: none;
    }
    .article-footer {
      max-width: none;
      width: 100%;
    }
  }

  .ck-content-container {
    padding: 0 60px 20px 60px;

    background-color: white;
    z-index: 1;
    overflow: auto;
  }

  .disclaimer {
    page-break-before: always;
    page-break-after: avoid;
    p {
      font-size: 9pt;
      text-align: justify;
      color: #525252;
      line-height: 1.5;
    }
  }

  &.pdf {
    position: relative;
    min-height: 230mm;
    height: auto;
    background-color: white;

    .ck-content .image > img {
      width: auto;
      max-height: 170mm;
      margin: 0 auto;
    }

    .ck-content p {
      margin-left: 0 !important;
    }

    .ck-content video {
      display: none;
    }
    .ck-content figure.table {
      max-height: inherit;
      overflow: inherit;
    }
    .ck-content figure.media {
      max-height: 500px;
      display: none;
      iframe {
        display: none;
      }
    }

    .ck-content .table table.font-sm tr > td {
      page-break-inside: avoid;
      padding: 6px !important;
    }

    .ck-content .table table.font-sm {
      border-collapse: collapse;
    }

    .ck-content .table table.font-sm tr > td h2 {
      font-size: 9pt !important;
    }
    .ck-content .table table.font-sm tr > td,
    .ck-content .table table.font-sm tr > td span,
    .ck-content .table table.font-sm tr > td p,
    .ck-content .table table.font-sm tr > td p mark,
    .ck-content .table table.font-sm tr > td p span mark,
    .ck-content .table table.font-sm tr > td p span {
      margin: 0;
      font-size: 8pt !important;
    }

    div[data-simplebar] {
      height: auto !important;
      max-height: none !important;
    }

    .article {
      min-height: auto;
      position: unset;
      box-shadow: none;
      z-index: 20;
    }

    .article-footer {
      display: block;
      width: 100%;
      position: fixed;
      clear: both;
      bottom: 0;
      z-index: -1;
      padding-top: 0;
      margin-top: 0;
      padding-bottom: 0;
    }

    .ck-content,
    .ck-content-container {
      padding: 0;
      min-height: auto;
    }

    figure.table table {
      max-height: none;
    }

    .pdf-mode {
      transform: scale(0.7) translate(-80px, -230px);
      max-height: 220mm;
    }
  }

  .loading-overlay {
    width: 100%;
    position: absolute;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: white;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    .spinner {
      margin: 0 auto;
    }
  }

  &.loading {
    overflow: hidden;

    .loading-overlay {
      opacity: 1;
      visibility: visible;
      transition: none;
    }
  }
`;

export const FooterText = styled.div`
  width: 100%;
  font-size: 12px;
  text-align: center;
  padding-bottom: 20px;
  min-width: 500px;
  color: ${injectColor('textFooter')};
`;

export const DownloadLink = styled.a`
  display: flex;
  color: ${injectColor('mainDarker')};
  padding: 30px 60px 0 40px;
  margin-bottom: -45px;
  z-index: 10;
  font-size: 13px;
  font-weight: 500;
  align-items: center;
  justify-content: flex-end;
  user-select: none;
  opacity: 0.8;
  height: 40px;
  ${(props) => props.disabled && 'opacity: .5; cursor: not-allowed;'}
  svg {
    margin-right: 10px;
    path:last-child {
      fill: ${injectColor('mainDarker')};
    }
  }

  &:hover {
    ${(props) => !props.disabled && 'cursor: pointer; opacity: 1;'}
  }
`;
