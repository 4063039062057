import React from 'react';
import { useParams } from 'react-router-dom';
import Ellipsis from '../Ellipsis';

import {
  SideMenuCategoryContainer,
  SideMenuArticlesContainer,
  MenuItem
} from './styled.js';
import New from '../New';

import PropTypes from 'prop-types';

const Triangle = ({ className, size, color }) => (
  <svg
    className={`svg-figure ${className || ''}`}
    style={{ width: size, height: size }}
  >
    <path
      d={`M ${size / 2},0 l -${size / 2},${size - 1} l ${size},0 Z`}
      fill={color}
    />
  </svg>
);

const SideMenuCategory = ({
  id,
  title,
  slug,
  active,
  updated_at,
  ...props
}) => {
  const { rootCategory } = useParams();

  return (
    <SideMenuCategoryContainer data-test-id="documentation-nav_category-container">
      <MenuItem
        onClick={(e) => {
          if (active) {
            e.preventDefault();
          }
        }}
        to={{
          pathname: `/${rootCategory}/${slug}`,
          search: window.location.search
        }}
        active={active}
        data-test-id="documentation-nav_category"
      >
        <Triangle className="triangle" size={7} color="#8b8c8c" />
        <Ellipsis
          text={title}
          width={150}
          data-test-id="documentation-nav_category-title"
        />
        <New category={id} />
      </MenuItem>
      <SideMenuArticlesContainer
        active={active}
        hasChildren={!!props.children}
        data-test-id="documentation-nav_category-articles"
      >
        {active ? props.children : null}
      </SideMenuArticlesContainer>
    </SideMenuCategoryContainer>
  );
};

SideMenuCategory.propTypes = {
  active: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  updated_at: PropTypes.string
};

export default SideMenuCategory;
