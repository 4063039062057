import React, { useCallback } from 'react';
import { Telemetry } from 'c2-common-ui';

import { ReactComponent as CyberCubeLogo } from '../../../assets/logo.svg';
import historyInstance from '../../../historyInstance';
import { BUTTON, CLICK, CMS, VISIT_HOME } from '../../../variables/telemetryVariables';

import { BrandStyled } from './SideBrand.style';

const SideNavBrand = () => {
  const handleClick = useCallback(() => {
    historyInstance.push('/');

    Telemetry.addUIEventMetric({
      action: CLICK,
      app: CMS,
      component: BUTTON,
      event: VISIT_HOME,
      feature: CMS
    });
  }, []);

  return (
    <BrandStyled data-test-id="app-sidenav_brand" onClick={handleClick}>
      <CyberCubeLogo />
    </BrandStyled>
  );
};

export default SideNavBrand;
