import styled from 'styled-components';

const BadgeStyled = styled.div`
  background: #00a690;
  color: white;
  border-radius: 10px;
  display: inline-block;
  padding: 2px 4px;
  font-weight: 500;
  font-size: 9px;
  margin-left: 4px;
`;

export { BadgeStyled };
