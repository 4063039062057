import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { Location } from 'history';

export interface IQueryConfiguration extends Location {
  search: string;
  hideSidenav: boolean;
  onlyArticle: boolean;
  pdf: boolean;
}

export type QueryConfigType = () => IQueryConfiguration;

const useQueryConfig: QueryConfigType = () => {
  const location = useLocation();
  const search = location?.search || '';
  const onlyArticle = Boolean(search && search.indexOf('article_mode') >= 0);

  return useMemo(
    () => ({
      ...location,
      hideSidenav:
        onlyArticle || Boolean(search && search.indexOf('sidenav=off') >= 0),
      onlyArticle,
      pdf: Boolean(search && search.indexOf('pdf') >= 0)
    }),
    [location, search, onlyArticle]
  );
};

export default useQueryConfig;
