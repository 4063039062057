/* eslint-disable no-unused-vars */
import { Application, ConfigInitialize } from 'c2-common-ui';
import * as Sentry from '@sentry/react';

import localAppConfigObj from './app.config.json';
import {
  apiPrefixEnv,
  sentryApiDsnEnv,
  telemetryApiKeyEnv,
  telemetryDbNameEnv,
  telemetryUrlEnv
} from './constants/environmentVariables';
import history from 'historyInstance';
import {
  AppConfigObj,
  getSessionIdToken,
  setEnvToSession,
  setInitialAppConfig,
  setSessionDevMode
} from './utils/sessionUtils';

export const doApplicationConfig = (id_token: string, isDevMode: boolean, appConfigObj: AppConfigObj) => {
  const configObject: ConfigInitialize = {
    devMode: {
      enabled: !id_token && isDevMode
    },
    userInactivityTime: 7200000,
    apiPrefix: apiPrefixEnv ? apiPrefixEnv : appConfigObj.apiPrefix,
    telemetry: {
      apiKey: telemetryApiKeyEnv ? telemetryApiKeyEnv : appConfigObj.telemetryApiKey,
      appSyncUrl: telemetryUrlEnv ? telemetryUrlEnv : appConfigObj.telemetryUrl,
      dbName: telemetryDbNameEnv ? telemetryDbNameEnv : appConfigObj.telemetryDbName,
      tableName: window.location.href.toString().indexOf('localhost') !== -1 ? 'ui-metrics' : 'ui-app'
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  Application.Config(configObject);
};

export const isAppInDevMode = () => {
  const isDevEnvToken = process.env.REACT_APP_IS_DEV_MODE === 'true';

  return !!isDevEnvToken && window.location.href.toString().indexOf('localhost') !== -1;
};

export const startApp = async () => {
  const idToken = getSessionIdToken();
  const isDevMode = isAppInDevMode();

  let appConfig = localAppConfigObj as AppConfigObj;

  if (!isDevMode) {
    const res = await fetch('/app.config.json?time=' + Date.now());
    const fetchedAppConfig = (await res.json()) as AppConfigObj;

    appConfig = fetchedAppConfig;
  }

  const appEnvironment = appConfig?.environment;
  const sentryApiDsn = sentryApiDsnEnv || appConfig?.SentryApiDsn || '';
  const sentryTracesSampleRate = appConfig?.SentryTracesSampleRate || 1.0;

  setEnvToSession(appEnvironment);

  // Sentry must load before everything else
  Sentry.init({
    dsn: sentryApiDsn,
    beforeSend: (event, hint) => {
      // eslint-disable-next-line no-console
      console.error(hint.originalException || hint.syntheticException);

      return event;
    },
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: sentryTracesSampleRate,
    normalizeDepth: 10, // Suggested for Redux depth
    environment: appEnvironment
  });

  doApplicationConfig(idToken, isDevMode, appConfig);

  // don't run logout handler if cms is initialized in iframe
  // this prevents c2-ui or other apps that run cms in iframe to log out due to inactivity in cms
  if (!isInIframe()) {
    Application.Bootstrap.initIdleLogoutHandler();
  }

  setInitialAppConfig(appConfig);

  if (isDevMode) {
    // eslint-disable-next-line no-console
    setSessionDevMode();
  }
};

function isInIframe () {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
