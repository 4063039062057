// import { Application, Fetch, SessionStorage } from "c2-common-ui";

// const appConfigObj = require('./app.config.json');

export default async function initCommonUI() {
  window.addEventListener('message', (event) => {
    // IMPORTANT: check the origin of the data!
    if (
      event.origin.indexOf('cybcube.com') >= 0 &&
      !sessionStorage.getItem('id_token') &&
      event.data &&
      typeof event.data === 'string'
    ) {
      if (event.data === 'clearSession') {
        sessionStorage.clear();
        localStorage.setItem('clearSessionStorage', 'true');
        localStorage.removeItem('clearSessionStorage');
      } else {
        const sessionObj = JSON.parse(event.data);

        for (const key in sessionObj) {
          if (sessionObj.hasOwnProperty(key)) {
            sessionStorage.setItem(key, sessionObj[key]);
          }
        }

        localStorage.setItem(
          'updateSessionStorage',
          JSON.stringify(sessionStorage)
        );
        localStorage.removeItem('updateSessionStorage');
      }
      // The data was sent from your site.
      // Data sent with postMessage is stored in event.data:
    } else {
      // The data was NOT sent from your site!
      // Be careful! Do not use it. This else branch is
      // here just for clarity, you usually shouldn't need it.
      return;
    }
  });
}

export function delay(ms = 0) {
  // @ts-ignore

  return new Promise((r) => setTimeout(() => r(), ms));
}
