import styled from 'styled-components';
import { injectColor } from '../../theme';

/* Customize the label (the container) */
export const CheckboxContainer = styled.label`
  align-items: center;
  align-content: center;
  height: 18px;
  display: flex;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  b {
    text-align: left;
    font-size: 12px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border-radius: 2px;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 4px;
      height: 9px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input:checked ~ .checkmark {
    background-color: ${injectColor('main')};
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
`;
