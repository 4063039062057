import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Clear } from 'assets/close.svg';
import { ReactComponent as Search } from 'assets/magnifying-glass-24.svg';
import { injectColor } from '../../theme';
import Spinner from '../Spinner';
import PropTypes from 'prop-types';

const InputContainer = styled.div`
  position: relative;
  margin-top: -4px;
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid #dee0e0;
  border-radius: 2px;
  min-width: 24vw;

  .clear-icon {
    margin-right: 12px;
    margin-left: 12px;
  }
  .spinner {
    width: 12px;
    height: 12px;
    border-width: 2px;
    margin: 0 12px;
  }
`;

const StyledAddon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    margin: 0 8px;
  }
`;

const StyledInput = styled.input`
  background: white;
  padding: 4px 8px 4px 0;
  outline: none;
  font-size: 14px;
  flex: 2;
  color: ${injectColor('textDark')};

  ::-ms-clear {
    display: none;
  }

  &:disabled + .clear-icon {
    cursor: not-allowed;
  }
  &:not(:disabled) + .clear-icon {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
      transition: opacity 0.2s ease-in-out;
    }
  }
`;

const Input = (({ addon, inputHeight, onClear, loading, ...props }, ref) => {
  const height = inputHeight || 38;

  //TODO: must update React and then make sure innerRef property turns to ref for StyledInput
  return (
    <InputContainer style={{ height }}>
      {addon && (
        <StyledAddon>
          {loading ? (
            <Spinner className="spinner" size={16} />
          ) : addon === 'search' ? (
            <Search />
          ) : (
            addon
          )}
        </StyledAddon>
      )}
      <StyledInput innerRef={ref} {...props} />
      {onClear && <Clear className="clear-icon" onClick={onClear} />}
    </InputContainer>
  );
});

Input.propTypes = {
  addon: PropTypes.oneOf([PropTypes.node, PropTypes.oneOf(['search'])]),
  inputHeight: PropTypes.number,
  loading: PropTypes.bool,
  onClear: (props, propName, componentName) => {
    const fn = props[propName];

    if (fn === undefined) {
      return;
    }
    if (!fn.prototype || (typeof fn.prototype.constructor !== 'function' && fn.prototype.constructor.length !== 0) ) {
      return new Error(`Invalid prop ${propName} suppliedTo ${componentName}.  Must make sure ${propName} has 0 arguments only.`);
    }
  }
};

export default Input;
