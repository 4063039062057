import React, { useCallback, useMemo, useState } from 'react';
import HubspotForm from 'react-hubspot-form';
import Modal from 'components/Modal/Modal';
import Spinner from 'components/Spinner';
import { getUserParam } from 'utils';
import { FormWrapper } from './HubspotFormPopup.style.js';
import { setHTMLInputValue } from './utils';
import PropTypes from 'prop-types';

const HubspotFormPopup = ({
  id,
  portal,
  label,
  clickElement
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const open = useCallback(() => setModalOpen(true), []);
  const close = useCallback(() => setModalOpen(false), []);
  const onSubmit = useCallback(() => {
    setSubmitted(true);
  }, []);
  const onFormReady = useCallback(() => {
    const token = sessionStorage.getItem('id_token');
    const email = document.querySelector('.hs-form-field input[name="email"]');
    // const product = document.querySelector(
    //   '.hs-form-field input[name="TICKET.product_impacted"]'
    // );

    if (email && token) {
      setHTMLInputValue(email, getUserParam(token, 'email'));
    }
    setSubmitted(false);

    // const email = setHTMLInputValue;
  }, []);

  const handlerWithClickEvent = useMemo(
    () => React.cloneElement(clickElement, { onClick: open }),
    [clickElement, open]
  );

  return (
    <>
      {handlerWithClickEvent}
      <Modal
        open={modalOpen}
        onClose={close}
        buttons={
          <>
            <button
              type="button"
              data-test-id="hs-form_cancel"
              onClick={close}
              className="btn-default"
            >
              Cancel
            </button>
            <button
              data-test-id="hs-form_submit"
              type="submit"
              form={`hsForm_${id}`}
              className="btn-primary"
              disabled={submitted}
            >
              Submit
            </button>
          </>
        }
        title={label}
      >
        <FormWrapper data-test-id="hs-form">
          <HubspotForm
            portalId={portal}
            formId={id}
            onReady={onFormReady}
            onSubmit={onSubmit}
            loading={
              <Spinner className="spinner" data-test-id="hs-form_spinner" />
            }
          />
        </FormWrapper>
      </Modal>
    </>
  );
};

HubspotFormPopup.propTypes = {
  clickElement: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  portal: PropTypes.string.isRequired
};

export default HubspotFormPopup;
