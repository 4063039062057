/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import classNames from 'classnames';
import { CMSProvider } from 'c2-cms-plugin';
import { SessionStorage } from 'c2-common-ui';

import { initGA } from './analytics';
import ContentProvider from './components/ContentProvider';
import historyInstance from './historyInstance';
import useQueryConfig from './hooks/useQueryConfig';
import Article from './pages/Article';
import Session from './Session';
import theme from './theme';
import { env } from './utils';

import './App.scss';
import './swagger.css';
import 'c2-cms-plugin/dist/index.css';
import 'simplebar/dist/simplebar.min.css';
import 'swagger-ui-react/swagger-ui.css';

initGA();

const currEnv = env();

const App = () => {
  const API_URL = SessionStorage.getItem('appConfig.apiPrefix');

  const { pdf } = useQueryConfig();

  return (
    <div className={classNames('App', { pdf })} data-test-id="app">
      <CMSProvider
        devMode={currEnv.local || currEnv.local_dev}
        ui={API_URL}
        api={API_URL}
        history={historyInstance}
        app="DOCS"
      >
        <ThemeProvider theme={theme}>
          <Switch>
            <Route exact path={'/staticViews/session.html'}>
              <Session />
            </Route>
            <Route path="/widget/:app" component={() => <React.Fragment />} />
            <Route exact path={'/print'}>
              <Article />
            </Route>
            <Route component={ContentProvider} />
          </Switch>
        </ThemeProvider>
      </CMSProvider>
    </div>
  );
};

export default App;
