import React from 'react';
import PropTypes from 'prop-types';
import { Button, Session, SessionStorage, Telemetry } from 'c2-common-ui';
import * as Sentry from '@sentry/react';

import Popup from '../../Popup';
import {
  BUTTON,
  CLICK,
  CMS,
  USER_LOGGED_OUT
} from '../../../variables/telemetryVariables';

const SideNavUserMenu = ({ children }) => {
  const doLogout = async () => {
    try {
      await Telemetry.addUIEventMetric({
        action: CLICK,
        app: CMS,
        component: BUTTON,
        event: USER_LOGGED_OUT,
        feature: CMS
      });
    } catch (err) {
      Sentry.captureException(err);

      // eslint-disable-next-line no-console
      console.error('Telemetry call failed');
    }

    Session.doLogout();
  };

  return (
    <Popup
      content={
        <>
          <div className="heading fullname">
            {SessionStorage.getItem('fullName')}
          </div>
          <div className="email">{SessionStorage.getItem('email')}</div>
          <Button
            classes={'logout-button'}
            color={'secondary'}
            onClick={doLogout}
          >
            Logout
          </Button>
        </>
      }
    >
      {children}
    </Popup>
  );
};

SideNavUserMenu.propTypes = {
  children: PropTypes.element
};

export default SideNavUserMenu;
