const reduceFontSizeForWideTables = (maxColumns) => {
  const tables = document.getElementsByTagName('table');

  for (let i = 0; i < tables.length; i++) {
    const table = tables[i];

    if (table && table.rows && table.rows.length) {
      const row = table.rows[0];

      if (row && row.cells && row.cells.length > maxColumns) {
        table.className = (table.className ? table.className : '') + 'font-sm';
      }
    }
  }
};

export { reduceFontSizeForWideTables };
