/* eslint-disable one-var */
import { Fetch } from 'c2-common-ui';

import { getQueryStringValue } from '../hooks/useQueryState';

const API_PATH = window.location.href.toString().indexOf('localhost') !== -1 ? 'http://localhost:3000/' : '';

const getContentModeParam = (firstparam) => {
  const mode = getQueryStringValue('mode', window.location.search);
  const preview = getQueryStringValue('preview', window.location.search);
  const modes = (typeof mode === 'object' ? mode : (mode ? [mode] : [])) || [];

  if (modes.length) {
    const query = modes.reduce((prev, curr) => prev + '&mode=' + curr, '');

    return (
      `${firstparam ? '?' : ''}${query}` +
      (preview ? `&preview=${preview}` : '')
    );
  }
  return firstparam ? '?' : '';
};

export const getAllRoles = () => Fetch.get(`${API_PATH}cms/role-groups`);
export const getConfigurations = () => Fetch.get(`${API_PATH}cms/configurations`);
export const getRootCategories = () => Fetch.get(`${API_PATH}cms/categories${getContentModeParam(true)}`);