import styled from 'styled-components';
import { injectColor } from '../../../theme';

export const BrandStyled = styled.div`
  width: 80px;
  height: 80px;
  min-height: 80px;
  align-items: center;
  justify-content: center;
  background-color: #373b40;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05), 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  color: ${injectColor('mapSeparatorLighter')};
  display: flex;
  cursor: pointer;

  > svg {
    transform: scale(0.6);

    path:nth-child(1) {
      display: none;
    }
  }

  &:hover {
    background-color: ${injectColor('RGBADark')};
    color: white;
  }
`;
