import styled from 'styled-components';
import { injectColor } from '../../theme';

export const MapStyled = styled.div`
  display: flex;
  justify-content: center;
  min-width: 1100px;
  max-width: 1200px;
  background: white;
  &.pdf-mode {
    min-width: 210mm;
    max-width: 210mm;
    > div {
      .tree-map__box-title {
        font-size: 11pt;
      }
      .tree-map__box-line {
        font-size: 11pt;
      }
      .tree-map-root {
        margin-top: 20px;

        .root-title {
          font-size: 12pt;
        }
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
  > div {
    display: flex;

    .tree-map-root {
      font-family: Roboto, sans-serif;

      .root-title {
        width: 250px;
        height: 40px;
        border-radius: 4px;
        background-color: ${injectColor('mapHeader')};
        color: ${injectColor('textDarker')};
        font-weight: 500;
        font-size: 16px;
        padding: 8px 12px;
      }

      .category {
        font-size: 13px;
        letter-spacing: 0.5px;
        color: ${injectColor('textDarker')};
        font-weight: normal;
        text-transform: uppercase;
        margin-left: 10px;
        margin-bottom: -2px;
        border-left: 2px solid ${injectColor('mapSeparatorLighter')};
        padding: 20px 20px 0;
      }

      .tree-map__item-container {
        &:nth-child(2) {
          .tree-map__item-arrow {
            .dot {
              display: block;
            }
          }
        }

        &:last-child {
          margin-bottom: 40px;
          border: none;
          margin-left: 12px;
        }
      }

      &:not(:last-child) {
        margin-right: 64px;
      }
    }
  }
`;
