import React, { Component } from 'react';
import { BadgeStyled } from './Badge.style';
import PropTypes from 'prop-types';

export default class Badge extends Component {
  render() {
    return (
      <BadgeStyled className={`badge badge-${this.props.color} ${this.props.className ? this.props.className : ''}`}>
        {this.children}
      </BadgeStyled>
    );
  }
}

Badge.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['success', 'primary'])
};