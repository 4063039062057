import ReactGA from 'react-ga';
import { env } from './utils';

export const TRACKING_ID = 'UA-132449908-7',
             DEFAULT_TRACKER_NAME = 'clientTracker',
             DIMENSIONS = {
               ID: 'dimension5',
               ROLE: 'dimension7',
               TENANT_ID: 'dimension2',
               TENANT_NAME: 'dimension1',
               EMAIL: 'dimension3'
             };

export const setGAUserParameters: (a: any) => void = ({
  tenantName,
  tenantId
}) => {
  const groups: string = sessionStorage.getItem('groups') || '[]';
  const serializedGroups: string[] = [];

  try {
    const items: string[] = JSON.parse(groups);

    serializedGroups.push(...items);
  } catch (e) {}
  ReactGA.set(
    {
      [DIMENSIONS.ID]: sessionStorage.getItem('username') || 'Unknown',
      [DIMENSIONS.ROLE]: serializedGroups.length
        ? serializedGroups.join(', ')
        : '-',
      [DIMENSIONS.TENANT_ID]: tenantId || 'Unknown',
      [DIMENSIONS.TENANT_NAME]: tenantName || 'Unknown',
      [DIMENSIONS.EMAIL]: sessionStorage.getItem('email') || 'Unknown'
    },
    [DEFAULT_TRACKER_NAME]
  );
};

class Analytics {
  static trackPage(page?: string) {
    ReactGA.pageview(
      page || window.location.pathname + window.location.search,
      [DEFAULT_TRACKER_NAME]
    );
  }

  static trackSearch(keyword) {
    Analytics.trackEvent('Dashboard', 'search', keyword);
  }

  static trackEvent(category, action, label, value?) {
    ReactGA.event(
      {
        category,
        action,
        label,
        value
      },
      [DEFAULT_TRACKER_NAME]
    );
  }

  static trackException(errMsg, isFatal) {
    ReactGA.exception(
      {
        exDescription: errMsg,
        exFatal: isFatal
      },
      [DEFAULT_TRACKER_NAME]
    );
  }
}

export const initGA = () => {
  ReactGA.initialize(TRACKING_ID, {
    gaOptions: {
      name: DEFAULT_TRACKER_NAME,
      alwaysSendReferrer: false
    }
  });

  const env_def = env();

  // Track events from platform apps about widget and notifications usage
  window.addEventListener('message', (event) => {
    // Ignores messages from untrusted domains.
    if (!env_def.local && !event.origin.endsWith('cybcube.com')) {
      return;
    }

    if (event.type === 'message') {
      let data: any = {};

      try {
        data = JSON.parse(event.data);
      } catch (e) {}

      switch (data.type) {
        case 'track':
          Analytics.trackEvent(
            data.category,
            data.action,
            data.label,
            data.value
          );
          break;
        case 'track_page':
          Analytics.trackPage(data.page);
          break;
        default:
          break;
      }
    }
  });
};

export default Analytics;
