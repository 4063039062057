import React, { useCallback, useEffect } from 'react';

const Session = () => {
  const receive = useCallback((event) => {
    if (event.data === 'clearSession') {
      sessionStorage.clear();
      localStorage.setItem('clearSessionStorage', 'true');
      localStorage.removeItem('clearSessionStorage');
    } else {
      const sessionObj = JSON.parse(event.data);

      for (const key in sessionObj) {
        if (sessionObj.hasOwnProperty(key)) {
          sessionStorage.setItem(key, sessionObj[key]);
        }
      }

      localStorage.setItem(
        'updateSessionStorage',
        JSON.stringify(sessionStorage)
      );
      localStorage.removeItem('updateSessionStorage');
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', receive, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <span />;
};

export default Session;
