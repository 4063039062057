import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const CURRENT_NDA_VERSION = '1.0';
export const ReadNDAContext = React.createContext(true);
export const UpdateReadNDAContext = React.createContext(() => {});

export const ReadNDAProvider = ({ children }) => {
  const [readNDA, setReadNDA] = useState(true);

  useEffect(() => {
    const readNDADetails = JSON.parse(sessionStorage.getItem('read_nda_details'));

    if (readNDADetails) {
      const { version } = readNDADetails;

      if (version !== CURRENT_NDA_VERSION) {
        setReadNDA(false);
      } else {
        setReadNDA(true);
      }
    } else {
      setReadNDA(false);
    }
  }, [setReadNDA]);

  return (
    <ReadNDAContext.Provider value={readNDA}>
      <UpdateReadNDAContext.Provider value={setReadNDA}>
        {children}
      </UpdateReadNDAContext.Provider>
    </ReadNDAContext.Provider>
  );
};

ReadNDAProvider.propTypes = {
  children: PropTypes.node
};
