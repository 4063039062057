import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
// import BrowserInteractionTime from 'browser-interaction-time';
// import { Telemetry } from 'c2-common-ui';

import Footer from '../../components/Footer';
import NDAModal from '../../components/NDAModal';
import Search from '../../components/Search';
import { FooterText } from '../../components/Footer/Footer.style';
import { getModifiedSearch } from '../../hooks/useQueryState';
import { ReadNDAContext } from '../../services/NDAService';
import { copyright } from '../../utils';

import Page from '../Page';

import GroupNavigation from './GroupNavigation';
import RootCategory from './RootCategory';
import { CategoriesContainer, ContentContainer } from './Home.style';

const Home = ({
  accessGroups,
  documentationCategory,
  loading,
  roots
}) => {
  const history = useHistory();
  const readNDA = React.useContext(ReadNDAContext);

  // const metricsObj = {
  //   timeOpened: 0,
  //   lastSessionTime: 0
  // };

  // const sendMetricsToTimestream = () => () => {
  //   if (metricsObj.timeOpened) {
  //     Telemetry.addCmsTimeEventMetric({
  //       event: 'track_homepage',
  //       page: 'home',
  //       timeOpened: metricsObj.timeOpened,
  //       timeSpent: Math.round(browserInteractionTime.getTimeInMilliseconds())
  //     });
  //   }
  // };

  // const browserInteractionTime = new BrowserInteractionTime({
  //   timeIntervalEllapsedCallbacks: [{
  //     timeInMilliseconds: 1000,
  //     callback: sendMetricsToTimestream('Interval reached. Sending metrics...'),
  //     multiplier: x => x * 1.5
  //   }],
  //   browserTabInactiveCallbacks: [sendMetricsToTimestream('Tab inactive. Sending metrics...')],
  //   idleTimeoutMs: 7000
  // });

  React.useEffect(() => {
    history.replace({
      pathname: window.location.pathname,
      search: getModifiedSearch({ root: undefined })
    });

    // browserInteractionTime.startTimer();

    // metricsObj.timeOpened = Date.now();

    // return (() => {
    // sendMetricsToTimestream('Refreshed/Left page. Sending metrics...');
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      id="home-container"
      addons={<Search />}
      title="Documentation"
      loading={loading}
    >
      <NDAModal />
      <GroupNavigation accessGroups={accessGroups} />
      <SimpleBar
        className="home-scroll"
        style={{ height: 'calc(100vh - 186px)', padding: '0 60px' }}
      >
        <ContentContainer>
          {readNDA && accessGroups
            .filter((group) => roots[group.id] && roots[group.id].length)
            .map((group) => (
              <React.Fragment key={group.id}>
                <div id={`group-${group.id}`} className="category-group__title">
                  {group.title}
                </div>

                <CategoriesContainer data-test-id="home-categories-container">
                  {roots[group.id].map((r, index) => (
                    <RootCategory
                      className="root-category"
                      key={index}
                      root={r}
                    />
                  ))}
                </CategoriesContainer>
              </React.Fragment>
            ))}
          {readNDA && accessGroups.length > 0 && (
            <FooterText className="page-footer" data-test-id="page-footer">
              {copyright}
            </FooterText>
          )}
        </ContentContainer>
      </SimpleBar>
      <Footer
        documentationCategory={documentationCategory}
        absolutePositioning
      />
    </Page>
  );
};

Home.propTypes = {
  accessGroups: PropTypes.any,
  documentationCategory: PropTypes.any,
  loading: PropTypes.bool,
  roots: PropTypes.any
};

export default Home;
