import styled from 'styled-components';
import { injectColor } from '../../theme';

const ModalStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  min-height: 50vh;
  padding: 0 24px;
  box-shadow: 0 4px 32px 0 rgba(45, 46, 46, 0.16),
    0 0 4px 0 rgba(45, 46, 46, 0.2);
`;

const ModalHeader = styled.div`
  padding: 16px 24px;
  background-color: ${injectColor('bgLightDarkest')};
  color: ${injectColor('textDarker')};
  border-bottom: 1px solid ${injectColor('separator')};
  font-size: 22px;
  font-weight: 500;
  width: 100%;
  text-align: left;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const ModalBody = styled.div`
  padding: 24px;
  flex: 1;
  width: 100%;
  text-align: justify;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner {
    margin: 0 auto;
  }
`;

const ModalFooter = styled.div`
  padding: 16px 24px;
  border-top: 1px solid ${injectColor('separator')};
  width: 100%;
  display: flex;
  justify-content: flex-end;

  > *:not(:last-child) {
    margin-right: 16px;
  }
  button {
    cursor: pointer;
    border-radius: 2px;
    font-family: Roboto, sans-serif;
    padding: 11px 16px;
    font-size: 14px;
    font-weight: 600;
    &.btn-default {
      background-color: white;
      color: #0e8fc9;
    }
    &.btn-primary {
      background-color: #0e8fc9;
      color: white;
    }
    &:disabled {
      opacity: 0.4;
      cursor: default;
    }
  }
`;

const OverlayContainer = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: default;
  z-index: 3000;
  justify-content: center;
`;

export { ModalStyled, ModalHeader, ModalBody, ModalFooter, OverlayContainer };
