import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext
} from 'react';
import SimpleBar from 'simplebar-react';
import { isEqual } from 'lodash';
import {
  ContentContext
} from 'services/ContentService';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import useMounted from 'hooks/useMounted';
import { getQueryStringValue } from 'hooks/useQueryState';
import Ellipsis from 'components/Ellipsis';
import { sortOrder } from 'utils';
import SideMenuCategory from './SideMenuCategory.js';
import { SideMenuContainer, MenuItem } from './styled.js';
import New from '../New';

import BrowserInteractionTime from 'browser-interaction-time';
import { Telemetry } from 'c2-common-ui';
import PropTypes from 'prop-types';
import { ARTICLE, CLICK, CLICK_ARTICLE, CMS, SIDENAV } from '../../variables/telemetryVariables';

const SideMenu = ({
  loading,
  categories,
  articles
}) => {
  const mounted = useMounted();
  const service = useContext(ContentContext);
  const history = useHistory();
  const params = useParams();

  const [
    activeCategory,
    setActiveCategory
  ] = useState(null);

  const openFirstArticleIfNoneSelected = useCallback(
    (category) => {
      if (!mounted.current) {
        return;
      }
      if (category && !params.article) {
        const rootArticle = category.articles?.find(
          (a) => a.slug === params.category
        );
        const first =
          !rootArticle &&
          (category.articles?.length ?? -1 > 0
            ? category.articles?.sort(sortOrder)?.[0].slug
            : '');

        if (first) {
          history.replace({
            pathname: `/${params.rootCategory}/${category.slug}/${first}`,
            search: window.location.search
          });
        }
      }
    },
    [history, params.category, params.article, params.rootCategory, mounted]
  );

  const loadDetails = useCallback(
    async (slug) => {
      if (!mounted.current) {
        return;
      }
      if (categories) {
        let currentCategory = categories.find((cat) => cat.slug === slug);

        if (!currentCategory) {
          const rootId = String(getQueryStringValue('root'));

          currentCategory = service?.categoryCache[rootId];
        }
        if (currentCategory) {
          if (
            service &&
            !currentCategory.articles &&
            !currentCategory.loading
          ) {
            currentCategory.loading = true;

            const {
              sub_categories,
              articles
            } = await service.fetchSubCategoriesAndArticles(
              currentCategory.slug
            );

            currentCategory.loading = false;
            currentCategory.articles = articles;
            currentCategory.sub_categories = sub_categories;
          }
          return currentCategory;
        }
        if (service && service.categoryCache) {
          const rootId = String(getQueryStringValue('root')) || '';

          if (service.categoryCache[rootId]) {
            service.categoryCache[rootId].sub_categories = categories;
          }
        }
      }
    },
    [categories, service, mounted]
  );

  const onCategoryChange = useCallback(
    async (slug) => {
      if (!mounted.current) {
        return;
      }
      const curr = slug
        ? slug
        : params.category
          ? params.category
          : params.rootCategory;
      let selected;

      if (
        curr &&
        service &&
        (!activeCategory || curr !== activeCategory?.slug)
      ) {
        selected = await loadDetails(curr);
        if (selected && mounted.current) {
          setActiveCategory(selected);
          openFirstArticleIfNoneSelected(selected);
        }
      }
    },
    [
      mounted,
      openFirstArticleIfNoneSelected,
      service,
      params,
      activeCategory,
      loadDetails
    ]
  );

  useEffect(() => {
    if (!mounted.current) {
      return;
    }
    onCategoryChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, params.rootCategory, params.category, mounted]);

  const metricsObj = {
    timeOpened: 0,
    lastSessionTime: 0
  };

  const sendMetricsToTimestream = msg => time => {
    if (metricsObj.timeOpened && params.article) {
      Telemetry.addCmsTimeEventMetric({
        event: 'track_article',
        page: 'docs',
        category: `${params.category}`,
        article: `${params.article}`,
        timeOpened: metricsObj.timeOpened,
        timeSpent: Math.round(browserInteractionTime.getTimeInMilliseconds())
      });
    }
  };

  const browserInteractionTime = new BrowserInteractionTime({
    timeIntervalEllapsedCallbacks: [{
      timeInMilliseconds: 1000,
      callback: sendMetricsToTimestream('Interval reached. Sending metrics...'),
      multiplier: x => x * 1.5
    }],
    browserTabInactiveCallbacks: [sendMetricsToTimestream('Tab inactive. Sending metrics...')],
    idleTimeoutMs: 7000
  });

  useEffect(() => {
    if (params.article) {
      browserInteractionTime.startTimer();
      metricsObj.timeOpened = Date.now();
    }

    return () => {
      if (params.article) {
        sendMetricsToTimestream('Refreshed/Left page. Sending metrics to timestream...');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.article]);

  return (
    <SideMenuContainer data-test-id="documentation-nav">
      <SimpleBar
        style={{
          maxHeight: 'calc(100vh - 148px)',
          minHeight: '100%',
          overflowX: 'hidden'
        }}
        direction="vertical"
      >
        {!loading && (
          <CategoryList categories={categories} active={params.category}>
            {((params.category === activeCategory?.slug &&
              activeCategory?.articles?.length) ??
              -1) > 0 && (
              <ArticleList
                category={params.rootCategory}
                articles={activeCategory?.articles}
              />
            )}
          </CategoryList>
        )}
        <ArticleList root articles={articles} category={params.rootCategory} />
      </SimpleBar>
    </SideMenuContainer>
  );
};

const ArticleList = ({ articles, category, root }) => {
  const { params } = useRouteMatch();
  const byGroups = useMemo(
    () =>
      articles
        ? articles.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.group || 'default']: [
              ...(prev[curr.group || 'default'] || []),
              curr
            ].sort(sortOrder)
          }),
          {}
        )
        : {},
    [articles]
  );
  const groups = Object.keys(byGroups).sort((a) =>
    a === 'default' ? -1 : 0
  );

  return (
    <>
      {groups.map((key) => (
        <div key={key} data-test-id="articles-list">
          {key !== 'default' && (
            <span className="group" data-test-id="articles-list_group-label">
              {key}
            </span>
          )}
          {byGroups[key].map((article, index) => {
            const articleUnderscored = article.slug.replaceAll('-', '_');
            const categoryUnderscored = category.replaceAll('-', '_');

            console.log(
              `${CLICK_ARTICLE}_${categoryUnderscored}_${articleUnderscored}`
            );

            return (
              <MenuItem
                data-test-id="articles-list_item"
                key={article.slug + index + category}
                onClick={(e) => {
                  Telemetry.addUIEventMetric({
                    action: CLICK,
                    app: CMS,
                    component: SIDENAV,
                    event: `${CLICK_ARTICLE}_${categoryUnderscored}_${articleUnderscored}`,
                    feature: ARTICLE
                  });

                  if (
                    article.slug === (root ? params.category : params.article)
                  ) {
                    e.preventDefault();
                  }
                }}
                subItem
                to={{
                  pathname: `/${params.rootCategory}/${
                    !root && params.category ? params.category + '/' : ''
                  }${article.slug}`,
                  search: window.location.search
                }}
                active={
                  article.slug === (root ? params.category : params.article)
                }
              >
                <Ellipsis
                  data-test-id="articles-list_item_title"
                  text={article.title}
                  width={150}
                />
                <New article={article.id} />
              </MenuItem>
            );
          }) ?? <span />}
        </div>
      ))}
    </>
  );
};

ArticleList.propTypes = {
  articles: PropTypes.array,
  category: PropTypes.string,
  root: PropTypes.bool
};

const CategoryList = React.memo(
  ({ categories, active, children }) =>
    categories?.sort(sortOrder).map((item, index) => (
      <SideMenuCategory
        id={item.id}
        updated_at={item.updated_at}
        key={item.slug + index}
        title={item.title}
        active={active === item.slug}
        slug={item.slug}
      >
        {children}
      </SideMenuCategory>
    )) ?? <span />,
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
);

const iDetailedCategoryShape = {
  loading: PropTypes.bool
};

iDetailedCategoryShape.articles = PropTypes.arrayOf(PropTypes.shape(iDetailedCategoryShape));
iDetailedCategoryShape.sub_categories = PropTypes.arrayOf(PropTypes.shape(iDetailedCategoryShape));

SideMenu.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    slug: PropTypes.string,
    order: PropTypes.number,
    category: PropTypes.bool,
    article: PropTypes.bool,
    updated_at: PropTypes.string,
    created_at: PropTypes.string
  })),
  categories: PropTypes.arrayOf(PropTypes.shape(iDetailedCategoryShape)),
  loading: PropTypes.bool
};

export default React.memo(SideMenu, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
