/* eslint-disable react/jsx-no-bind */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { ContentContext } from 'services/ContentService';
import { useRouteMatch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Analytics from 'analytics';
import cx from 'classnames';
import qs from 'query-string';
import SimpleBar from 'simplebar-react';
import SwaggerUI from 'swagger-ui-react';

import {
  ArticleContainer,
  Container,
  FooterText,
  DownloadLink
} from './Article.style';
import { copyright } from 'utils';
import { ReactComponent as Close } from 'assets/close.svg';
import { ReactComponent as DownloadSVG } from '../../assets/download-24.svg';
import { reduceFontSizeForWideTables } from './table.adjust';
import ErrorFallback from 'components/ErrorFallback';
import Spinner from 'components/Spinner';
import TreeMap from 'components/TreeMap';
import useMounted from 'hooks/useMounted';
import useQueryConfig from 'hooks/useQueryConfig';
import PropTypes from 'prop-types';
const videoBeingPlayed = () => {
  const videos = document.getElementsByTagName('video');

  for (let i = 0; i < videos.length; i++) {
    if (!videos[i].paused) {
      return true;
    }
  }
  return false;
};

const CHECK_CONTENT_EVERY_MS = 5000;

let timer = 0;

const formatMathContent = () => {
  window.MathJax.Hub.Queue([
    'Typeset',
    window.MathJax.Hub,
    document.getElementById('cybercube-content')
  ]);
};

const ArticleRenderer = ({ category, onArticleChange }) => {
  const mounted = useMounted();
  const [loading, setLoading] = useState(false);
  const scrollRef = useRef(null);
  const articleRef = useRef(null);
  const [showPreview, setShowPreview] = useState(undefined);
  const service = useContext(ContentContext);
  const { hideSidenav, onlyArticle, pdf, search } = useQueryConfig();
  const { params } = useRouteMatch();

  const [article, setArticle] = useState();
  const onChange = useCallback(
    (article) => {
      if (mounted.current) {
        setArticle(article);
        if (onArticleChange) {
          onArticleChange(article);
        }
        setLoading(false);
      }
    },
    [onArticleChange, mounted]
  );

  const fetchArticle = useCallback(
    async (service, key, categorySlug, articleSlug, rootId) => {
      const { articleCache } = service;

      articleCache[key] = { state: 'loading' };
      const article = await service.fetchArticle(
        categorySlug,
        articleSlug,
        rootId
      );

      articleCache[key] = {
        state: article
      };
      return article;
    },
    []
  );

  const load = useCallback(async () => {
    if (service) {
      const { articleCache } = service;
      const articleSlug = params.article ? params.article : params.category;

      if (
        (!params.article &&
          (category?.articles?.map((i) => i.slug).indexOf(articleSlug) ?? -1)) <
        0
      ) {
        onChange(undefined);
        return;
      }
      const categorySlug = params.article
        ? params.category
        : params.rootCategory;
      const articleKey =
        categorySlug && articleSlug && categorySlug + articleSlug;
      const rootId = parseInt(qs.parse(search)['root_id']);

      if (articleKey && !articleCache[articleKey] && service) {
        setLoading(true);
        const a = await fetchArticle(
          service,
          articleKey,
          categorySlug,
          articleSlug,
          rootId
        );

        onChange(a);
      }
      if (
        articleCache[articleKey].state &&
        articleCache[articleKey].state !== 'loading' &&
        articleCache[articleKey].state.id !== article?.id
      ) {
        onChange(articleCache[articleKey].state);
      }
    }

    const content = document.getElementsByClassName('ck-content');

    if (content.length) {
      const images = content[0].getElementsByTagName('img');

      for (let i = 0; i < images.length; i++) {
        const img = images[i];

        img.style.cursor = 'pointer';
        img.onclick = () => {
          setShowPreview(img.src);
        };
      }
    }
    formatMathContent();
  }, [onChange, category, service, params, search, fetchArticle, article]);

  const loadPDF = useCallback(async () => {
    const data = await window.getData();

    await setArticle(data);
    await setLoading(false);

    const event = window.document.createEvent('HTMLEvents');

    event.initEvent('app-ready', true, true);
    window.dispatchEvent(event);

    const images = document.getElementsByTagName('img');

    for (let i = 0; i < images.length; i++) {
      if (images[i].src.indexOf('.gif') >= 0) {
        images[i].style.display = 'none';
        let parent = images[i].parentElement;

        if (parent && parent.style && parent.tagName === 'FIGURE') {
          parent.style.display = 'none';
          parent = parent.parentElement;

          if (parent && parent.style && parent.children.length === 1) {
            parent.style.display = 'none';
            const nextEl = parent.nextElementSibling;

            if (
              nextEl &&
              nextEl.style &&
              nextEl.tagName === 'P' &&
              nextEl.innerHTML === '&nbsp;'
            ) {
              nextEl.style.display = 'none';
            }
          }
        }
      }
    }
    reduceFontSizeForWideTables(4);
    formatMathContent();
  }, []);

  const downloadPDF = useCallback(
    (e) => {
      if (!article?.allow_pdf || !article?.pdf_link) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [article]
  );

  useEffect(() => {
    if (pdf) {
      if (window.getData) {
        loadPDF();
      } else {
        load();
      }
    } else {
      load();

      timer = setInterval(() => {
        if (!videoBeingPlayed()) {
          load();
        }
      }, CHECK_CONTENT_EVERY_MS);
      Analytics.trackPage();

      if (scrollRef.current) {
        const scrollEl = scrollRef.current.getScrollElement();

        scrollEl.scrollTop = 0;
      }
    }

    return () => {
      if (timer) {
        clearInterval(timer);
        timer = 0;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, pdf]);

  const jsonData = article?.data;
  const footerText = (
    <FooterText className="article-footer" data-test-id="article-page-footer">
      {copyright}
    </FooterText>
  );

  const hasPDFDownload = !pdf && article?.allow_pdf && article?.pdf_link;

  const renderArticle = (
    <div className={`article ${!hasPDFDownload ? 'no-pdf' : ''}`}>
      {hasPDFDownload && (
        <DownloadLink
          data-test-id="download-pdf"
          href={article?.pdf_link}
          target="_blank"
          disabled={!article?.pdf_link}
          onClick={downloadPDF}
        >
          <DownloadSVG />
          Download article
        </DownloadLink>
      )}
      {!jsonData?.openapi && (
        <div
          id="cybercube-content"
          className="ck-content ck-content-container"
          data-test-id="article-content"
        >
          {article && (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  article.content &&
                  article.content.split('>&nbsp;<').join('><')
              }}
            />
          )}

          {article?.data?.treeMap && (
            <TreeMap
              data-test-id="article-treemap"
              className={pdf ? 'pdf-mode' : ''}
              {...article?.data?.treeMap}
            />
          )}
        </div>
      )}

      {jsonData?.openapi && (
        <>
          <SwaggerUI data-test-id="swagger-spec" spec={jsonData} />
        </>
      )}

      {footerText}
    </div>
  );

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <Container
        id="article-container"
        data-test-id="documentation_container"
        onlyArticle={onlyArticle}
        pdf={pdf}
      >
        {showPreview && (
          <div
            className="preview-overlay"
            onClick={() => setShowPreview(undefined)}
          >
            <div className="preview" onClick={(e) => e.stopPropagation()}>
              <Close
                className="close"
                onClick={() => setShowPreview(undefined)}
              />
              <img src={showPreview} alt="preview" />
            </div>
          </div>
        )}
        <ArticleContainer
          hideSidenav={hideSidenav}
          ref={articleRef}
          className={cx({ loading, pdf, treeMap: article?.data?.treeMap })}
          onlyArticle={onlyArticle}
        >
          {pdf ? (
            renderArticle
          ) : (
            <SimpleBar
              ref={scrollRef}
              style={{ height: 'calc(100vh - 148px)' }}
            >
              <div className="with-panel">
                {loading ? (
                  <div className="article">
                    <div className="loading-overlay">
                      <Spinner className="spinner" data-test-id="spinner" />
                    </div>
                  </div>
                ) : (
                  renderArticle
                )}
              </div>
            </SimpleBar>
          )}
        </ArticleContainer>
      </Container>
    </Sentry.ErrorBoundary>
  );
};

ArticleRenderer.propTypes = {
  category: PropTypes.object,
  documentationCategory: PropTypes.object,
  onArticleChange: PropTypes.func,
  onCategoryChange: PropTypes.func
};

export default ArticleRenderer;
