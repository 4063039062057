import React from 'react';
import * as Sentry from '@sentry/react';

import Analytics from '../analytics';
import { getRootCategories } from '../webApis';

export const GENERAL_DOCUMENTATION_SLUG = 'documentation';

const useContent = (history, service) => {
  // Component States
  const [accessGroups, setAccessGroups] = React.useState([]);
  const [documentation, setDocumentation] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [roots, setRoots] = React.useState({});

  const loadRootCategories = React.useCallback(async () => {
    if (service && accessGroups.length === 0) {
      setLoading(true);

      try {
        const resGetRootCategories = await getRootCategories();

        const accessGroups = {};
        const docsCategory = resGetRootCategories.find((cat) => cat.slug === GENERAL_DOCUMENTATION_SLUG);
        const otherCategories = resGetRootCategories.filter((cat) => cat.slug !== GENERAL_DOCUMENTATION_SLUG);
        const roots = otherCategories.reduce((prev, curr) => {
          const key = curr.access_group?.id || '0';

          return {
            ...prev,
            [key]: prev[key] ? prev[key].concat([curr]) : [curr]
          };
        }, {});

        if (docsCategory) {
          // eslint-disable-next-line require-atomic-updates
          service.categoryCache[docsCategory.id] = docsCategory;

          setDocumentation(docsCategory);
        }

        otherCategories.forEach((cat) => {
          if (cat.access_group) {
            accessGroups[cat.access_group.label] = cat.access_group;
          }
        });

        setAccessGroups(Object.values(accessGroups).sort((a, b) => a.title > b.title ? 1 : -1));
        setLoading(false);
        setRoots(roots);
      } catch (err) {
        Sentry.captureException(err);

        // eslint-disable-next-line no-console
        console.log('error', err);
      }
    }

    Analytics.trackPage();
  }, [accessGroups, service]);

  return {
    accessGroups,
    documentation,
    loadRootCategories,
    loading,
    roots,
    service
  };
};

export default useContent;
