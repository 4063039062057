import styled from 'styled-components';

export const SearchStyled = styled.div`
  .react-autosuggest__suggestions-container {
    position: relative;
    background-color: white;
    z-index: 1000;
    margin: 8px 0 0;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 0;

    &--open {
      overflow-x: hidden;
    }

    .react-autosuggest__suggestions-list {
      padding: 0;
      margin: 4px 0 0;
    }
    .react-autosuggest__section-container--first {
      .react-autosuggest__section-title {
        div {
          margin: 0;
          border: none;
        }
      }
    }
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input::-ms-clear {
    display: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__container {
    z-index: 1000;
  }
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      padding: 0;
    }
    .react-autosuggest__suggestion--highlighted > div {
      background-color: #ddd;
    }
  }

  .bottom-link {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 8px 12px;
    color: #0b74a3;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    justify-content: flex-start;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
  }
`;

export const SuggestionTitleStyled = styled.div`
  color: #2a5a70;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  padding: 20px 16px 10px 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  margin-top: 8px;
`;

export const SuggestionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
  margin: 0;
  background-color: white;
  > div:not(.badge) {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: #525252;
  }
`;
