import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import ErrorFallback from 'components/ErrorFallback';
import Header from '../../components/Header';
import Spinner from '../../components/Spinner';
import useQueryConfig from '../../hooks/useQueryConfig';

import { PageStyled } from './Page.style';

const Page = ({
  addons,
  backType,
  children,
  className,
  id,
  loading,
  title
}) => {
  const { hideSidenav } = useQueryConfig();

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <PageStyled
        hideSidenav={hideSidenav}
        data-test-id={`${id}-container`}
        className={className || ''}
      >
        <Header title={title} addons={addons} backType={backType} />
        {loading && <Spinner className="spinner" data-test-id="spinner" />}
        {children}
      </PageStyled>
    </Sentry.ErrorBoundary>
  );
};

Page.propTypes = {
  addons: PropTypes.object,
  backType: PropTypes.oneOf(['back', 'home']),
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.string
};

export default Page;
