import React from 'react';

const ErrorFallback = () => {
  return (
    <div>
      Unfortunately there has been an error. Please refresh and try again.
    </div>
  );
};

export default React.memo(ErrorFallback);
