import * as history from 'history';

const baseHistory = history.createBrowserHistory();

export default {
  ...baseHistory,
  pushWithQuery: function (location: string, search?: string) {
    baseHistory.push({
      pathname: location,
      search: search || window.location.search
    });
  },
  updateSearch: function (search: string) {
    baseHistory.replace({ pathname: window.location.pathname, search });
  },
  back: function () {
    baseHistory.go(-2);
  },
  home: function () {
    baseHistory.push({
      pathname: '/',
      search: window.location.search
    });
  }
};
