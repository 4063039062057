import styled from 'styled-components';
import { injectColor } from '../../theme';

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  overflow: auto;
  max-width: 100%;

  width: calc(
    100vw - ${(props) => (props.hideSidenav ? 0 : props.theme.sizes.sidenav)}px
  );

  .version {
    position: absolute;
    display: flex;
    align-items: center;
    right: 10px;
    bottom: 6px;
    font-size: 9px;
    color: ${injectColor('textFooter')};
  }

  ${(props) =>
    props.absolutePositioning &&
    `
      z-index: 1;
      position: absolute;
      bottom: 0;
      left: 0;
  `}
`;

const FooterNav = styled.div`
  position: relative;
  background-color: ${injectColor('bgFooter')};
  height: 64px;
  width: 100%;
  display: flex;
  border-top: 1px solid ${injectColor('separator')};
  min-width: 500px;

  .footer-links {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    max-width: ${(props) => (props.article ? '1080px' : 'none')};

    @media (min-width: 940px) {
      margin-left: ${(props) =>
    props.article ? props.theme.sizes.sidemenu : 0}px;
    }
  }

  .separator {
    margin-left: 32px;
    margin-right: 32px;
    width: 1px;
    height: 32px;
    background-color: ${injectColor('bgFooterSeparator')};
  }
`;

const FooterLink = styled.div`
  display: flex;
  align-items: center;
  color: ${injectColor('textDark')};
  font-size: 14px;
  font-weight: 400;
  svg {
    margin-right: 12px;
  }

  &:hover {
    cursor: pointer;
    svg {
      g > path {
        transition: fill 0.3s ease-in-out;
        fill: ${injectColor('main')};
      }
    }
  }
`;

const FooterText = styled.div`
  width: 100%;
  padding: 12px 0;
  font-size: 12px;
  flex-shrink: 0;
  text-align: center;
  color: ${injectColor('textFooter')};
`;

export { FooterContainer, FooterLink, FooterNav, FooterText };
