import React from 'react';
import PropTypes from 'prop-types';
import { Telemetry } from 'c2-common-ui';

import { BUTTON, CLICK, CMS } from '../../../variables/telemetryVariables';

import './styles.scss';

const GroupNavigation = ({ accessGroups }) => (
  <div className="group-navigation-container">
    <div className="group-navigation-links-wrapper">
      {accessGroups.length ? <span>Jump to:</span> : <span />}
      {accessGroups.map(({ id, title }, index) => (
        <GroupNavigationLink
          id={id}
          key={id}
          last={index < accessGroups.length - 1}
          title={title}
        />
      ))}
    </div>
  </div>
);

const GroupNavigationLink = ({ id, last, title }) => {
  const handleScrollToLink = () => {
    document.getElementById(`group-${id}`).scrollIntoView({
      behavior: 'smooth'
    });

    const clickEvent = `scroll_to${title}`;

    const
      formattedEvent = clickEvent
        .replace(/\d+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map((word) => word.toLowerCase())
        .join('_');

    Telemetry.addUIEventMetric({
      action: CLICK,
      app: CMS,
      component: BUTTON,
      event: formattedEvent,
      feature: CMS
    });
  };

  return (
    <div id={id} className="group-navigation-link" onClick={handleScrollToLink}>
      {title}
      {last && (<div className="separator" />)}
    </div>
  );
};

GroupNavigationLink.propTypes = {
  id: PropTypes.number,
  last: PropTypes.bool,
  title: PropTypes.string
};

GroupNavigation.propTypes = {
  accessGroups: PropTypes.array
};

export default GroupNavigation;
