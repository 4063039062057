import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

import { ContentContext } from '../../services/ContentService';
import {
  getQueryStringValue,
  setQueryStringValue
} from '../../hooks/useQueryState';
import { ReadNDAContext } from '../../services/NDAService';
import { SideMenuContainer } from '../../components/SideMenu/styled';
import Analytics from '../../analytics';
import ErrorFallback from 'components/ErrorFallback';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import NDAModal from '../../components/NDAModal';
import Search from '../../components/Search';
import SideMenu from '../../components/SideMenu';
import Spinner from '../../components/Spinner';
import useMounted from '../../hooks/useMounted';
import useQueryConfig from '../../hooks/useQueryConfig';

import Article from '../Article';

import {
  DocumentationContainer,
  DocumentationContent
} from './Documentation.style';
import { Telemetry } from 'c2-common-ui';
import { ARTICLE, CMS, OPEN, OPEN_ARTICLE, PAGE } from '../../variables/telemetryVariables';

const extractContent = (contents, slug) =>
  contents?.find((c) => c.slug === slug);

const Documentation = ({ documentationCategory }) => {
  // Component States
  const [article, setArticle] = React.useState();
  const [category, setCategory] = React.useState();
  const [loading, setLoading] = React.useState();

  const readNDA = React.useContext(ReadNDAContext);
  const service = React.useContext(ContentContext);

  const history = useHistory();
  const mounted = useMounted();
  const { hideSidenav, onlyArticle } = useQueryConfig();
  const { params } = useRouteMatch();

  useEffect(() => {
    if (article) {
      const titleUnderscored = article.slug.replaceAll('-', '_');

      Telemetry.addUIEventMetric({
        action: OPEN,
        app: CMS,
        component: PAGE,
        event: `${OPEN_ARTICLE}_${titleUnderscored}`,
        feature: ARTICLE
      });
    }
  }, [article]);

  const load = React.useCallback(
    async (root = '') => {
      if (params.rootCategory && service && mounted.current) {
        const rootId = String(getQueryStringValue('root')) || root;

        if (
          service.categoryCache &&
          service.categoryCache[rootId] &&
          service.categoryCache[rootId].sub_categories
        ) {
          setCategory(service.categoryCache[rootId]);
        } else {
          setLoading(true);

          const category = await service.fetchSubCategoriesAndArticles(
            params.rootCategory,
            getQueryStringValue('root')
          );

          if (!mounted.current) {
            return;
          }

          // eslint-disable-next-line require-atomic-updates
          service.categoryCache[rootId] = category;
          setQueryStringValue('root', category.id);
          setCategory(category);
          setLoading(false);

          if (service && !params.category && !params.article) {
            if (category.sub_categories?.length ?? -1 > 0) {
              history.replace(
                `/${params.rootCategory}/${category.sub_categories?.[0]?.slug}${history.location.search}`
              );
            } else if (category.articles?.length ?? -1 > 0) {
              history.replace(
                `/${params.rootCategory}/${category.articles?.[0]?.slug}${history.location.search}`
              );
            }
          }
        }
      }
    },
    [history, mounted, params, service]
  );

  const openInNewTab = React.useCallback(() => {
    const currUrl = window.location.href.split('?')[0];

    window.open(currUrl, '_blank');
  }, []);

  const setupPDFLink = React.useCallback((article) => {
    setArticle(article);
  }, []);

  const helmet = React.useMemo(() => {
    const titles = [];

    if (category) {
      titles.push(category.title);
    }

    if (params.category) {
      const content = extractContent(category?.sub_categories, params.category);

      if (content) {
        titles.push(content.title);
      }

      if (params.article && content) {
        const article = extractContent(content.articles, params.article);

        if (article) {
          titles.push(article.title);
        }
      } else if (!content) {
        const rootArticle = extractContent(category?.articles, params.category);

        if (rootArticle) {
          titles.push(rootArticle.title);
        }
      }
    }
    return titles.reverse().join(' / ');
  }, [category, params]);

  const handleElementClick = React.useCallback(
    (e) => {
      let target = e.target;

      while (target && target.tagName !== 'A') {
        target = target.parentNode;
        if (!target) {
          return;
        }
      }

      if (
        target &&
        target.tagName === 'A' &&
        !target.attributes.disabled &&
        target.href
      ) {
        e.preventDefault();
        const relativePath = target.href.replace(window.location.href, '');

        if (relativePath.indexOf('#') === 0) {
          const el = document.getElementById(relativePath.replace('#/', ''));

          if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else {
          Analytics.trackEvent(`${helmet}`, 'click-link', target.href);
          if (target.href.startsWith(window.location.origin)) {
            history.push({
              pathname: target.href.replace(window.location.origin, ''),
              search: history.location.search
            });
          } else {
            window.open(target.href, '_blank');
          }
        }
      }
    },
    [helmet, history]
  );

  const handleCategoryChange = React.useCallback(
    async (category) => {
      if (mounted.current) {
        setCategory(category);
      }
    },
    [mounted]
  );

  const articleRoute = (
    <Route exact path={'/:rootCategory/:category/:article?'}>
      <Article
        onCategoryChange={handleCategoryChange}
        category={category}
        documentationCategory={documentationCategory}
        onArticleChange={setupPDFLink}
      />
    </Route>
  );

  React.useEffect(() => {
    if (!category || category.slug !== params.rootCategory) {
      load().catch((err) => {
        Sentry.captureException(err);

        // eslint-disable-next-line no-console
        console.error(err);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, params.rootCategory]);

  React.useEffect(() => {
    const docsBar = document.getElementById('docs-bar');
    const container = document.getElementById('article-container');

    if (container) {
      container.addEventListener('click', handleElementClick);
    }
    if (docsBar) {
      docsBar.addEventListener('click', handleElementClick);
    }
    return () => {
      const docsBar = document.getElementById('docs-bar');
      const container = document.getElementById('article-container');

      if (container) {
        container.removeEventListener('click', handleElementClick);
      }
      if (docsBar) {
        docsBar.removeEventListener('click', handleElementClick);
      }
    };
  }, [article, handleElementClick]);

  if (onlyArticle) {
    return articleRoute;
  } else if (loading) {
    return (
      <div className="overlay-wrapper">
        <Spinner className="spinner" data-test-id="spinner" />
      </div>
    );
  }

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <NDAModal />
      {readNDA && (
        <DocumentationContainer hideSidenav={hideSidenav}>
          <Helmet title={helmet} />
          <Header
            backType="home"
            title={category?.title}
            addons={
              <>
                <Search />
                {hideSidenav && (
                  <div className="open-in-new" onClick={openInNewTab}>
                    Open in new tab
                  </div>
                )}
              </>
            }
          />
          <DocumentationContent>
            {category ? (
              <SideMenu
                loading={loading}
                categories={category.sub_categories}
                articles={category.articles}
              />
            ) : (
              <SideMenuContainer className="menu-placeholder" />
            )}
            {articleRoute}
          </DocumentationContent>

          {!onlyArticle && (
            <Footer
              article
              onCategoryChange={handleCategoryChange}
              documentationCategory={documentationCategory}
            />
          )}
        </DocumentationContainer>
      )}
    </Sentry.ErrorBoundary>
  );
};

Documentation.propTypes = {
  documentationCategory: PropTypes.object
};

export default Documentation;
