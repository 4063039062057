import styled from 'styled-components';

import { injectColor } from 'theme';
import { device } from '../../responsive';
import { ie } from '../../utils';

export const HomeContainer = styled.div`
  position: relative;
  display: flex;
  background-color: ${injectColor('bgLightDarkest')};
  flex-direction: column;
  align-content: flex-start;

  width: calc(
    100vw - ${(props) => (props.hideSidenav ? 0 : props.theme.sizes.sidenav)}px
  );
  padding: 82px 0 64px 0;

  h2 {
    position: absolute;
    top: 80px;
    color: #a3a3a3;
    font-size: 32px;
  }

  .simplebar-content {
    height: 100%;
  }

  .page-header {
    z-index: 1000;
    position: absolute;
    left: 0;
    top: 0;
    width: calc(
      100vw -
        ${(props) => (props.hideSidenav ? 0 : props.theme.sizes.sidenav)}px
    );
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05), 0 3px 5px 0 rgba(0, 0, 0, 0.1);

    &__addons {
      margin-top: 23px;
    }
  }

  > .spinner {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 40%;
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;

  .category-group__title {
    max-width: 1000px;
    margin: 0 auto 20px auto;
    display: block;
    width: 100%;
    padding-top: 20px;
    font-size: 20px;
    font-weight: 400;
    ${ie() &&
    `
      max-width: 1080px;
      margin: 0 auto;
    `}
  }
`;

export const CategoriesContainer = styled.div`
  flex: 1;
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(1, minmax(250px, 1fr));
  grid-gap: 40px;
  margin: 0 auto 20px auto;
  grid-auto-rows: 200px;
  max-width: 1000px;
  background-color: ${injectColor('bgLightDarkest')};
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
  }
  @media ${device.laptop} {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }

  ${ie() &&
  `
    display: flex;
    align-items: flex-start;
    padding-top: 0;
    justify-content: flex-start;
    max-width: none;
    flex-wrap: wrap;
    align-content: flex-start;
    flex: 1 0 auto;
    margin: 0 auto;

    max-width: 1120px;

  .root-category {
      margin: 20px;

      min-width: 320px;
      width: 320px;
  }

  `}

  width: 100%;

  .page-footer {
  }
`;
