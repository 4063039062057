import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { PopupContainer, PopupStyled } from './Popup.style.js';

const Popup = ({ content, ...props }) => {
  const [open, setOpen] = useState(false);

  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    const current = wrapperRef.current;

    if (current && !current.contains(event.target)) {
      setOpen(false);
    }
  };

  const openMenu = useCallback(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <PopupStyled ref={wrapperRef}>
      {open && <PopupContainer>{content}</PopupContainer>}
      {React.cloneElement(props.children, { onClick: openMenu })}
    </PopupStyled>
  );
};

Popup.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired
};

export default Popup;
