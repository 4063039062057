import styled from 'styled-components';
import { injectColor, injectSize } from '../../theme';

export const SideNavStyled = styled.div`
  position: relative;
  height: 100vh;
  width: ${injectSize('sidenav')}px !important;
  min-width: ${injectSize('sidenav')}px;
  background-color: ${injectColor('bgDark')};
`;

export const BottomMenu = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const SideNavItem = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05), 0 3px 5px 0 rgba(0, 0, 0, 0.1);

  font-weight: 600;
  background-color: ${injectColor('bgDark')};

  &.preview {
    height: 40px;
    border-top: 2px solid ${injectColor('bgDarker')};
    border-bottom: 2px solid ${injectColor('bgDarker')};
    .preview-label {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .preview-heading {
      text-align: left;
      font-weight: 500;
      font-family: Roboto, sans-serif;
      margin-bottom: 16px;
      font-size: 13px;
      padding-left: 0;
    }
  }

  ${(props) =>
    props.active &&
    `
    background-color: ${props.theme.colors.main};
    &:hover {
    }
  `}

  ${(props) =>
    props.clickable &&
    `
      :hover {

        svg > g > path:last-child {
            fill: white;
        }

        background-color: ${props.theme.colors.bgDarker};
        cursor: pointer;
        transition: all .3s ease-in-out;
      }
    `}
`;

export const SideNavUserMenuStyled = styled.div`
  position: relative;
`;

export const UserInfo = styled.div`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: white;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  font-weight: 500;
  font-size: 14px;
  user-select: none;
  cursor: pointer;
`;
